import React from 'react'
import { Link } from 'gatsby'

export default props => (
  <footer className='site-footer'>
    <p className='footer-contact'>
      Si tienes cualquier sugerencia o detectas algún problema en los datos, escríbenos a <a href='mailto:info@educalista.es' rel='noopener noreferrer' target='_blank'>info@educalista.es</a>
    </p>
    <ul className='footer-nav'>
      <li>
        <Link to='/aviso-legal' rel="nofollow">Aviso legal</Link>
      </li>
      <li>
        <Link to='/politica-de-privacidad' rel="nofollow">Política de privacidad</Link>
      </li>
      <li>
        <Link to='/politica-de-cookies' rel="nofollow">Política de cookies</Link>
      </li>
    </ul>
  </footer>
)
