import { sluggify } from './strings'
import { objectMap } from './object'

const filters = {
  fundingType: 'Tipo',
  educationFilter: 'Estudios',
}

const GLOBAL_PREFIX = '/colegios/'
const EMPTY_PATH = '/'

const FILTER_SLUG = {
  fundingType: {
    transformation: fundingType => sluggify(fundingType, '-') + 's',
    readableTransformation: fundingType => fundingType.toLowerCase() + 's',
    joiningString: '-y-',
    joiningReadableString: ' y ',
    prefix: '',
    readablePrefix: '',
    sortingOrder: 1,
  },
  educationFilter: {
    transformation: educationFilter => sluggify(educationFilter, '-'),
    readableTransformation: educationFilter => educationFilter,
    joiningString: '-y-',
    joiningReadableString: ' y ',
    prefix: 'con',
    readablePrefix: 'con',
    sortingOrder: 2,
  },
}

const SLUG_FILTER_TRANSFORMATION = {}

export const initializePossibleFilterValues = possibleFilters => {
  Object.keys(possibleFilters).forEach(filterType => {
    const filterValues = possibleFilters[filterType]
    filterValues.forEach(({ name: filterName, order: filterOrder }) => {
      const sluggifiedValue = FILTER_SLUG[filterType].transformation(filterName)
      SLUG_FILTER_TRANSFORMATION[sluggifiedValue] = {
        filterName,
        filterOrder,
        filterType,
      }
    })
  })
}

export const filterDisplayName = filterMachineName => filters[filterMachineName]

export const filtersToString = filters => {
  const stringifiedFilters = objectMap(filters, (filterKey, filterValue) =>
    !filterValue || filterValue.size === 0
      ? ''
      : [
          FILTER_SLUG[filterKey].readablePrefix,
          Array.from(filterValue)
            .map(filterItem => ({
              value: FILTER_SLUG[filterKey].readableTransformation(filterItem),
              slug: FILTER_SLUG[filterKey].transformation(filterItem),
            }))
            .sort(
              (a, b) =>
                SLUG_FILTER_TRANSFORMATION[a.slug].filterOrder -
                SLUG_FILTER_TRANSFORMATION[b.slug].filterOrder
            )
            .map(item => item.value)
            .join(FILTER_SLUG[filterKey].joiningReadableString),
        ]
          .join(' ')
          .trim()
  )

  const stringifiedFiltersJoined = Object.entries(stringifiedFilters)
    .sort(
      ([keyA], [keyB]) =>
        FILTER_SLUG[keyA].sortingOrder - FILTER_SLUG[keyB].sortingOrder
    )
    .map(([_, value]) => value)
    .filter(Boolean)
    .join(' ')

  return !stringifiedFiltersJoined ? '' : `Colegios ${stringifiedFiltersJoined}`
}

/**
 * Slug's structure: `colegios-${funding.join('-y-')-con-${education.join('-y-')}}
 *
 * For example: colegios-publicos-y-privados-con-primaria-y-bachillerato
 */

export const filtersToSlug = originalFilters => {
  /**
   * sluggedFilters = {
   *   educationFilter: 'con-primaria-y-bachillerato',
   *   fundingType: 'publicos-y-privados'
   * }
   */
  const filters = { ...originalFilters }
  Object.keys(filters).forEach(filterKey => {
    if (filters[filterKey].size === 0) {
      delete filters[filterKey]
    }
  })
  const sluggedFilters = objectMap(filters, (filterKey, filterValue) =>
    !filterValue || filterValue.size === 0
      ? ''
      : [
          FILTER_SLUG[filterKey].prefix,
          Array.from(filterValue)
            .map(FILTER_SLUG[filterKey].transformation)
            .sort(
              (a, b) =>
                SLUG_FILTER_TRANSFORMATION[a].filterOrder -
                SLUG_FILTER_TRANSFORMATION[b].filterOrder
            )
            .join(FILTER_SLUG[filterKey].joiningString),
        ]
  )
  const sluggedFiltersJoined = Object.entries(sluggedFilters)
    .sort(
      ([keyA], [keyB]) =>
        FILTER_SLUG[keyA].sortingOrder - FILTER_SLUG[keyB].sortingOrder
    )
    .flatMap(([_, value]) => value)
    .slice(1)
    .filter(Boolean)
    .join('-')

  return !sluggedFiltersJoined
    ? EMPTY_PATH
    : `${GLOBAL_PREFIX}${sluggedFiltersJoined}`
}

/**
 *
 * @param {*} slug
 * @returns {Object | null} if there's an unknown token in the slug this
 *   function returns null
 */
export const slugToFilters = slug => {
  if (slug === EMPTY_PATH) {
    return {}
  }

  const filters = {}

  const prefixesRegex = RegExp(
    `(?:${Object.values(FILTER_SLUG)
      .map(item => item.prefix ? item.prefix + '-' : item.prefix)
      .filter(prefix => prefix !== '')
      .join('|')})`,
    'g'
  )

  const sluggifiedValues = slug
    .split(GLOBAL_PREFIX)
    .filter(Boolean)[0]
    .split(prefixesRegex)
    .filter(Boolean)
    .flatMap(slugsByType => slugsByType.split('-y-')) // TODO add polyfill
    .map(string => string.replace(/(^-|-$)/g, ''))

  for (let i = 0; i < sluggifiedValues.length; i++) {
    const sluggifiedValue = sluggifiedValues[i]
    if (SLUG_FILTER_TRANSFORMATION[sluggifiedValue] === undefined) {
      return null
    }
    const { filterType, filterName } = SLUG_FILTER_TRANSFORMATION[
      sluggifiedValue
    ]
    if (!filters[filterType]) {
      filters[filterType] = new Set()
    }
    filters[filterType].add(filterName)
  }

  return filters
}
