const capitalize = inStr =>
  inStr
    .toLowerCase()
    .replace('i.e.s', 'I.E.S')
    .replace('c.e.i.p', 'C.E.I.P')
    .replace('c.p.i', 'C.P.I')
    .split(' ')
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(' ')

const internalReplacer = inStr =>
  inStr
    .toLowerCase()
    .replace(/á/g, 'a')
    .replace(/é/g, 'e')
    .replace(/í/g, 'i')
    .replace(/ó/g, 'o')
    .replace(/ú/g, 'u')
    .replace(/ü/g, 'u')
    .replace(/ñ/g, 'n')
    .replace(/\./g, '')
    .replace(/º/g, '')

const sluggify = (inStr, nonAlphabeticalChar = '') =>
  internalReplacer(inStr)
    .replace(/de /, '')
    .replace(/\W/g, nonAlphabeticalChar) // all non-word characters

const normalizeString = (inStr, nonAlphabeticalChar = '') =>
  internalReplacer(inStr).replace(/\W/g, nonAlphabeticalChar) // all non-word characters

module.exports = {
  capitalize,
  normalizeString,
  sluggify,
}
