import React from 'react'
import Svg from 'react-inlinesvg'
import { Link } from 'gatsby'
import LogoEducalista from './logoEducalista'

export default props => (
  <div className='site-header-small'>
    <div className='logo'>
      <Link to='/'>
        <LogoEducalista />
      </Link>
    </div>
    <div className='social'>
      <a
        href='https://twitter.com/educalista_es'
        target='_blank'
        rel='noopener noreferrer'
      >
        <Svg width={24} src='/images/logo-twitter.svg' />
      </a>
    </div>
  </div>
)
