import React from 'react'
import PropTypes from 'prop-types'
import Header from './header'
import HomeHeader from './homeHeader'
import Footer from './footer'
import { Helmet } from 'react-helmet'
import './../styles/styles.scss'

const Layout = ({ schools, filters, isFront, children, filterSchoolsCount }) => (
  <div className='page-container'>
    <Helmet>
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <link
        href='https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;600;700&family=Roboto:wght@400;700&display=swap'
        rel='stylesheet'
      />
    </Helmet>
    {isFront ? <HomeHeader schools={schools} filters={filters} filterSchoolsCount={filterSchoolsCount} /> : <Header />}
    {children}
    <Footer />
  </div>
)

Layout.propTypes = {
  filters: PropTypes.object.isRequired,
  isFront: PropTypes.bool,
  schools: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  filterSchoolsCount: PropTypes.number.isRequired
}

Layout.defaultProps = {
  isFilterLanding: false,
  isFront: false,
}

export default Layout
