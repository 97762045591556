import React from 'react'

const LogoEducalista = () => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    viewBox='0 0 360 94'
    style={{ enableBackground: 'new 0 0 360 94' }}
    xmlSpace='preserve'
  >
    <g id='tracing'>
      <path
        fill='#7269FE'
        d='M349.8,69.5c-2.2,0-4.3-0.8-5-3c-0.2-0.6-0.2-1.3-0.3-1.9c-0.2-0.8-0.6-1.5-0.8-2.3c-0.5-1.6-0.6-3.3-0.3-5
    c0.6-3.3,0.4-6.6-0.5-9.8c-0.4-1.4-1-2.8-1.6-4.1c-0.6-1.2-1.2-3.1-2.4-3.9c-1.4-0.9-3.3-1.4-4.9-1.8c-1.8-0.5-3.6-0.9-5.4-1.1
    c-3.4-0.4-6.8,0-10.1,1.2c-1.1,0.4-2.4,0.8-2.9,2c-0.5,1.4,0.2,3.3,0.4,4.7c0.1,0.7-0.3,3,0.5,3.4c0.8,0.5,2.6-0.7,3.4-1.1
    c2.7-1.2,10.6-4.3,11.8,0.4c0.2,0.8,0.3,1.8,0.2,2.6c-0.1,1.5-0.7,1.4-2,1.3c-2.9-0.3-5.8,0.1-8.5,1.3c-4.9,2.3-8.3,6.4-8.7,11.9
    c-0.2,2.4,0.2,4.8,1.2,6.9c0.5,1,1.1,2,1.9,2.8c1.2,1.2,2.7,1.5,4.3,1.9c3.4,0.9,7.1,1.4,10.6,0.5c1.9-0.5,3.3-1.4,5-2.2
    c1.5-0.7,2.4,0.2,3.6,1c3,2.2,6.2,1.9,9.6,1.6c0.7-0.1,1.7,0,2.2-0.6s0.5-2,0.6-2.7c0.1-0.6,0.5-2.2,0.2-2.9
    C351.6,70.2,350.3,69.5,349.8,69.5z M338.9,46.7c2.2-1.2,1.9,3.6,2,4.8c0.1,1.4,0.4,2.8,0.4,4.2c-0.1,1.3-0.8,1.6-1.9,1.2
    c0.5-0.7,1.1-1.4,1.6-2c-1-0.4-1.5-0.7-2.6-0.5c-1,0.2-2,0.5-3,0.7c-1.9,0.4-4.2,1.4-6.1,1.3c0.5-0.6,1.4-0.9,2.1-1.2
    c1-0.5,2-1,3-1.5c1.8-0.8,3.5-1,5.4-1.5c-1.5-0.4-2.6-0.4-4.1-0.3c0.7-0.6,1.6-1.2,2.1-2l0,0c0.5-0.5,1-1,1.4-1.5
    c-0.6-0.1-1.4,0-1.9-0.2c-0.3-0.1-0.4-0.5-0.7-0.6c-0.7-0.3-1.3,0.9-2,0.7c-0.3-0.1-0.7-0.7-0.6-1c0.1-0.5,0.6-0.6,1-0.7
    c0.6-0.2,1.2-0.5,1.9-0.3C337.4,46.4,338.4,47,338.9,46.7z M338.8,58.5c1,0.4-2.8,3.6-3.2,3.9c-0.3-1.2-0.4-2,0.6-3
    C336.8,58.8,337.9,58.1,338.8,58.5z M334.9,58c1-1.4,1.7-1.8,3.4-2c-0.2,0.6-0.7,1-1.1,1.5C336.6,56.9,335.6,57.7,334.9,58z
     M336,63.3c0.2,0,2.9-0.2,2.9-0.3c-0.4,0.8-2.4,1.3-3.2,1.6C335.6,64.3,335.3,63.4,336,63.3z M338.4,62.1c-0.1,0-0.3-0.1-0.4-0.2
    c0.7-0.9,1.3-1.7,2-2.6c0.3,0.3,0.5,0.6,1.1,1.3C340,61.3,339.2,61.7,338.4,62.1z M333.2,63c0.4,2.6-0.2,4.7-3,5.3
    c-2.3,0.5-6.1,0.8-5.9-2.5c0.2-3.2,2.8-6.1,5.5-7.4c1-0.5,2.4-1.1,3.1,0.1C333.5,59.6,333.2,61.7,333.2,63z M332.9,45.5
    c0-0.2-0.2-0.6-0.1-0.8c0.2-0.3,1.4-0.6,1.7-0.7c0.9-0.3,1.5-0.7,2.4-0.5C335.4,44.2,334.2,44.9,332.9,45.5z M334.2,50.5
    c1.2-0.6,2.5-1.1,3.8-1.5c-0.1,0.7-0.1,0.8-0.7,1.1c-0.3,0.2-0.8,0.3-1.2,0.5c-0.9,0.4-1.5,0.7-2.5,0.5
    C333.8,50.9,334,50.6,334.2,50.5z M338.5,42.9c0.2,0.8,0.4,1-0.3,1.5c-0.5,0.4-2.1,1.1-2.7,0.9C335.8,45,338.3,42.3,338.5,42.9z
     M322.3,40c0.9-0.5,1.8-1.1,2.8-1.5c0.8-0.4,1.2-0.3,2.1-0.3c-1.5,1.1-3.6,2.3-5.5,2.5C321.8,40.5,322,40.2,322.3,40z M318,39.9
    c0.9-0.4,2.5-1,3.5-0.8c-0.8,1.3-2.7,2.2-4.1,2.2C317.6,40.9,317.6,40.1,318,39.9z M328.2,41.3c-1.2,1.6-2.6,1.7-4.4,2.2
    c-2.1,0.6-3.8,1.9-5.9,1.8c0.1-0.6,0-1.2,0.2-1.7c0.2-0.7,0.8-0.8,1.5-1.1c1.6-0.6,3.3-1.1,5-1.6l-0.4,1.5c0.8,0,1.4,0,2.1-0.3
    c0.8-0.3,1.5-0.8,2.2-1.1C328.4,41.1,328.3,41.2,328.2,41.3z M326.7,40.7c0,0-0.2-0.1-0.3-0.2c0.5-0.6,1.1-1.2,1.6-1.9
    c0.5,0.3,0.7,0.5,0.9,0.7C328.2,39.8,327.4,40.2,326.7,40.7z M331.1,41.3l-1.2,0.3c1.7-1.8,4.1-2.6,6.3-1.2c0.3,0.2,1.2,0.7,1.3,1
    c0.1,0.6-0.5,0.7-1,0.8c-1.3,0.5-2.7,1-4.1,1.3c0.4-1,1-1.9,1.6-2.8C333,41,332.1,41.1,331.1,41.3z M331.7,52.4
    c0.1,0,0.2,0.1,0.3,0.1l-6.7,2.1C326.2,52.6,329.4,51.4,331.7,52.4z M316.7,60.1c0.3-0.7,0.5-1.5,0.9-2.2s1.3-1,2.1-1.3
    c0.8-0.4,1.7-0.8,2.5-1.2c1-0.5,1.1-0.5,2.1-0.2c-1.4,1-2.6,2.1-3.9,3.3c-1.1,1-2.9,3.2-4.3,1.8C316.4,60.2,316.7,60.2,316.7,60.1z
     M315.1,63.8c0.3-0.6,0.7-1.2,1.1-1.8c0.1,0.2,1.9-0.7,2-0.4s-1.5,1.7-1.6,2c-0.1,0.2,1.7,1.4,1.5,1.5c-0.3,0.2-3.2,1-3.3,1.2
    c-0.1-0.2,0.1-1.7,0-1.9C314.8,64.3,315.1,63.9,315.1,63.8z M317,69.7c-0.3,0.3-0.6,0.5-0.9,0.8c0.2-1,0.1-1.4,1-2.1
    c0.7-0.5,1.3-1.1,2-1.7c-0.7,0.2-1.2,0.4-1.9,0.4c1.5-1.3,4.1-2.4,4.8-4.3c-1.1,0.3-2.5,1.3-3.6,1.1c0.6-0.7,1-1.6,1.7-2.1
    c1-0.8,2.2-1.2,3.3-1.8c0.6-0.3,1.3-1.1,0.1-1.1c-1,0-2,1.4-3,1.6c0-0.7,0.6-0.9,1.2-1.2c1-0.6,2-1.3,3-1.9c1.5-1,3.1-2,4.6-3
    c-1.2,1.2-2.3,2.3-3.2,3.7c-0.9,1.4-1.8,2.9-2.7,4.3C321.5,65,319.4,67.6,317,69.7z M322.5,71c-0.5,1.3-2,2.2-3.1,3.3
    c-1-0.7-2.3-1.1-1.3-2.4c0.4-0.5,0.8-0.7,1.3-1c0.6-0.3,0.9-0.8,1.4-1.3c-0.7,0.2-1.2,0.3-1.8,0.2c0.8-0.9,1.6-1.8,2.7-2.3
    C322,68.6,322.9,70,322.5,71z M324.6,74.5c-0.2,0.2-0.7-0.1-1.1-0.2c0.3-0.6,0.6-1.2,0.9-1.8c-0.6-0.1-1.2,0-1.8,0
    c0.4-0.8,0.7-1.5,1.5-2c0.6-0.3,0.7-0.3,1.5-0.1c0.5,0.1,1,0.2,1.5,0.4c1.1,0.3,1.4,0.4,0.5,1.2C326.6,72.9,325.7,73.7,324.6,74.5z
     M337,70.2c-1.3,1.3-2.4,2.9-4.1,3.7c-1.4,0.7-3.7,1.3-5.3,0.8c1-0.9,2.2-1.7,3.3-2.5c1.1-0.8,2-1.3,2.5-2.6
    c-1.6,0.4-2.7,1.8-4.2,2.1c0.7-0.7,2.8-3.2,3.8-3.1c0.5,0.1,1.1,0.7,1.7,0.8c0.9,0.2,1.9,0.2,2.8,0.1C337.4,69.8,337.2,70,337,70.2
    z M336.7,68.1c-0.6,0.2-1.2-0.3-1.3-0.8c-0.1-0.8,0.7-1,1.4-1.3c1.3-0.6,2.6-0.7,4-1C339.5,66.2,338.3,67.6,336.7,68.1z
     M340.7,71.4c0.6-0.4,0.6-0.9,0.8-1.6c-0.7-0.2-1.3-0.3-2-0.2c0.7-0.4,2.1-0.8,2.4-1.6c0.4-1.1-0.8-1.4-1.6-1.4
    c0.8-0.6,1.3-1.1,2-0.1c0.3,0.4,0.4,0.9,0.7,1.3c0.3,0.5,0.9,0.7,1.2,1.2C345.3,70.5,341.6,71.2,340.7,71.4z M345.3,72.1
    c-0.6,0.7-1.6,2.4-2.5,2c0.4-0.7,0.8-2.2,1.4-2.7c0.2-0.1,0.5-0.4,0.9,0C345.2,71.5,345.4,72,345.3,72.1z M349.1,74
    c-0.7,1.4-2.6,0.9-3.8,0.9c1.2-1.5,2.5-2.5,4-3.6C349.3,72.2,349.5,73.2,349.1,74z'
      />
      <g id='t'>
        <path
          fill='#7269FE'
          d='M310.3,39.7c0,1.3,0.7,4.9-1.2,5.4c-2.4,0.5-5.4-1.8-7.5,0c-1.3,1-1.1,2.9-0.9,4.4c0.3,2.5,0.5,5,0.8,7.6
      c0.3,2.5,0.6,5,0.9,7.5c0.1,1,0,2.4,0.7,3.2c0.6,0.7,1.4,0.4,2.2,0.4c0.9,0,2.2-0.2,3,0.3c0.7,0.5,0.7,1.6,0.7,2.4
      c0,1.4,0.1,3-0.2,4.4c-0.1,0.9-0.5,1.4-1.4,1.5c-1.8,0.3-3.9,0-5.6-0.2c-2-0.2-4.1-0.5-6-1.2c-2.8-1.1-5-3.3-6.1-6.1
      c-1.8-4.7-1.1-10.1-1-15c0-2.7,0-5.6-0.3-8.3c-0.1-0.7-0.2-1.5-0.9-1.7c-0.5-0.1-0.9,0.3-1.3,0.4c-0.6,0.3-1.3,0.4-2,0.6
      c0.1-2.2,0-4.4-0.2-6.6c-0.2-1.7-0.3-2.9,1.7-3.2c1.1-0.2,3,0.7,3.8-0.4c0.8-1.1,0.1-3.4,0.1-4.5c-0.1-1.5-0.5-3.7,1.3-4.3
      c1-0.3,2.1,0,3.1,0c1.2,0,2.4-0.2,3.5-0.4c1.8-0.2,3.4,0.4,3.9,2.4c0.3,1.1,0.3,2.3,0.3,3.4c0,0.9-0.2,2.4,0.5,3.1
      c0.7,0.8,2.2,0.5,3.1,0.4c1.2,0,2.4-0.2,3.6-0.2c0.8,0,1.2,0.2,1.3,0.9C310.6,37,310.3,38.5,310.3,39.7z M301.1,73.8
      c1-0.6,2.1-1.1,3.1-1.7c0.8-0.4,2-0.8,2.4-1.7c-1.1,0.2-1.9,1-2.8,1.6C302.9,72.6,302,73.2,301.1,73.8z M297.1,58.3
      c-1.1,0.3-2.2,0.6-3.2,1c-1.3,0.4-2.7,1.6-2.9,3c1.1-0.1,2-1.1,2.9-1.7C294.9,59.8,296,59.1,297.1,58.3 M292.2,55.7
      c1.4-0.5,2.7-0.9,4.1-1.5c0.5-0.2,1.1-0.3,1.4-0.8c0.3-0.4,0.3-1,0.5-1.5c-0.8,0-1.1,0-1.8,0.4c-0.8,0.5-1.5,1-2.2,1.5
      c-0.6,0.4-1.5,0.9-2,1.4c-0.6,0.6-0.9,1.8-1.2,2.7c1.5-0.6,3.2-1.1,4.4-2.3C294.4,55.7,293.3,55.7,292.2,55.7z M301.8,43.2
      c0.9-0.1,1.8-0.3,2.8-0.4c0.7-0.1,1.4,0.1,2.1-0.1c1.1-0.2,1.6-1.2,1.4-2.3c-0.4-1.9-2.3-0.7-3.5-0.3c-1.9,0.7-3.3,1.4-4.8,2.6
      C300.5,42.9,301.3,43.1,301.8,43.2z M290,42.1c-0.1-0.2-0.1-0.3-0.2-0.5c-0.3,0.2-1.7,0.9-1.7,1.2c0,0.5,0.6,0.4,1,0.5
      c1.3,0.2,2.6,0.8,4,0.3s2.6-1.4,4-1.9c1.4-0.6,2.5-1,3.6-2C297.1,39.8,293.4,41.2,290,42.1z M287.9,37.3c-3.2-0.5-1.6,3.4-1.7,5.2
      c1.6-0.3,3-2,4.2-3c0.2-0.2,0.6-0.4,0.7-0.7c0.1-0.5,0-0.2-0.4-0.5C290,37.8,288.7,37.6,287.9,37.3z M291.6,67.3
      c0.2,0.5,0.3,1.3,1,1.4c0.6,0.1,1.5-0.6,1.9-0.9c1.3-0.9,2.8-1.7,3.9-2.8c-1-0.3-2.7,0.5-3.7,0.7c-0.6,0.2-1.4,0.3-2,0.6
      C292,66.5,292,66.8,291.6,67.3z M293.5,27.6c0-0.1,0-0.1,0-0.2c-0.7,0.1-1.4-0.1-1.6,0.7c-0.2,0.7,0.1,1.6,0.8,1.8
      c0.9,0.3,2.1-0.3,2.8-0.6c1-0.3,2.1-0.6,2.9-1.3C296.9,27.6,295.1,27.7,293.5,27.6z M297.6,73.4c2.1,0.6,3.9-1.3,4.9-3
      c-2.4-0.3-5.2,1.5-7.2,2.7C296,73.2,296.8,73.3,297.6,73.4z M290.4,50.9c0.5,0.1,1.1,0.5,1.6,0.4c0.6,0,1.6-0.9,2.1-1.2
      c1.2-0.7,2.9-1.4,3.6-2.7c-1.6,0.3-3.1,0.9-4.6,1.4C291.7,49.4,291.4,49.8,290.4,50.9z M293.2,33.2c1.6-0.4,3.2-0.7,4.7-1.2
      c1.3-0.5,1.3-1.3,1.8-2.5c-1.5,0.2-2.4,0.6-3.7,1.4c-1,0.6-2.6,1.2-3.2,2.2C293,33.1,293.2,33.2,293.2,33.2z M294.8,72
      c1-0.7,2.1-1.4,3.1-2.1c0.8-0.5,2-1,2.3-1.9c-1.7,0-3.5,1.1-5,1.8c-0.4,0.1-1.5,0.4-1.6,1C293.6,71.1,294.5,71.7,294.8,72z
       M301.2,38.1c-0.4-0.3-0.9-0.9-1.4-1.1c-0.7-0.2-1.4,0.4-2.1,0.7c-1.4,0.7-3.3,1.3-4.3,2.5c1.2,0.1,2.6-0.5,3.8-0.8
      C298.6,39.1,300,38.8,301.2,38.1z M299.9,59.8c-0.2-0.2-0.8-1.2-1.1-1.2c-0.4,0-1.7,1.1-2,1.3c-1.3,0.9-2.7,1.7-3.8,2.8
      C295.4,62.8,298,61.2,299.9,59.8z M290.4,46.7c0.1,1,0.2,1.3,0.2,1.7c1.9-1.4,3.8-2.6,5.7-3.9c-1.1,0.2-2.3,0.2-3.3,0.5
      C292.1,45.3,291.3,46.1,290.4,46.7z M306.2,36.7c-1.1,0.2-2.3,0.3-3.3,0.8c-1.1,0.5-0.5,1.2-0.3,2.2c1.7-0.8,3.3-1.6,4.8-2.7
      C307,36.9,306.6,36.9,306.2,36.7z M297.1,51c-1.2-0.2-2.4,0.5-3.6,0.9c-0.5,0.2-1.5,0.4-1.8,0.8c-0.3,0.3-0.2,0.9-0.3,1.3
      c1-0.3,1.8-0.5,2.7-1.1C295.1,52.2,296.1,51.6,297.1,51z M299.7,33.6c-1-0.3-2.1,0.5-3,1s-2.2,0.8-2.7,1.7c1,0,2.1-0.6,3-1
      C298.1,34.8,298.9,34.5,299.7,33.6z M293.7,48.2c1-0.6,1.9-1.1,2.9-1.7c0.9-0.5,1.6-0.7,1.9-1.6c-1.1,0-2.1,0.8-3,1.3
      s-2,1-2.5,1.8C293.1,48.1,293.2,48.1,293.7,48.2z M304.3,75.3c0.4-0.3,2.6-1.9,1.9-2.3c-0.5-0.3-3.1,1.5-3.7,1.7
      C302.9,74.8,303.3,75,304.3,75.3z M299.1,35.5c-1.2,0-2.4,0.7-3.5,1.2c-0.6,0.3-1.3,0.5-1.9,0.8c-0.7,0.4-0.6,0.6-0.9,1.4
      c1.3-0.2,2.4-1,3.6-1.7C297.3,36.6,298.2,36.1,299.1,35.5z M294,33.4c-0.6,0.3-1.4,0.8-2.2,1.2c0.7,0.7,0.7,0.7,1.6,0.3
      c0.7-0.3,1.5-0.7,2.2-1C295.2,33.7,294.8,33.6,294,33.4z M291.7,65.2c0.9-0.4,1.7-0.8,2.6-1.1c0.6-0.3,1.4-0.4,1.8-1
      c-0.9-0.2-2.2,0.5-3.1,0.7C292.4,64.1,290.8,64.5,291.7,65.2z M296.4,68.3c0.8-0.5,1.5-1.1,2.2-1.6c0.6-0.4,1.6-0.7,1.2-1.4
      c-0.8,0.5-1.5,1-2.3,1.6c-0.5,0.4-1.4,0.7-1.6,1.3C296.1,68.2,296.2,68.3,296.4,68.3z M298.7,56.6c0.7-0.3,0-1-0.4-1
      c-0.8,0.1-2.1,1.1-2.8,1.5C296.3,57,298.6,56.6,298.7,56.6z'
        />
      </g>
      <path
        fill='#7269FE'
        d='M276.9,58.7c-3-1.9-6.6-2.9-10-4.1c-1.5-0.5-3.3-1-4.7-2c-0.9-0.6-2-2.2-1.7-3.4c0.2-1,1.8-2.3,2.7-2.7
    c1.3-0.6,3.3-0.6,4.7-0.6c1.6,0,3.2,0.2,4.6,0.8c1.6,0.6,2.8,1.7,4.2,2.6c0.4-0.6,0.7-0.9,0.7-1.6c0.1-0.8,0-1.6-0.1-2.3
    c-0.1-1.3-0.1-2.7-0.4-4c-0.2-0.8-0.5-1.6-1.3-2c-1-0.5-2.2-0.1-3.3-0.3c-3.1-0.6-6.2-1.1-9.3-1.5c-2.6-0.3-5.3-0.4-7.7,0.6
    c-4.1,1.7-6.7,6-6.5,10.4c0.3,5.1,3.8,9.2,8.3,11.2c2.4,1.1,5,1.6,7.3,2.7c2.1,1,2.5,3.4,1.5,5.4s-3.1,2.7-5.2,2.3
    c-3-0.6-5.9-1.8-8.9-2.7c-1.3-0.4-2.9-1-3.1,0.8c-0.1,1.2,0,2.4,0,3.6s-0.4,3,0.8,3.7s3.2,0.9,4.6,1.3c1.5,0.4,3,0.8,4.6,1.1
    c2.8,0.6,5.8,0.9,8.7,0.6c5.3-0.5,11.2-3,13.3-8.3C282.4,66,280.8,61.1,276.9,58.7z M277,64.1c0.5-0.2,1.2,0.4,1.8,0.7
    c-0.3,0.4-0.5,1.1-0.9,1.4c-0.6,0.4-1.8,0.4-2.4,0.4c-1.5,0.2-2.9,0.4-4.5,0.1C272.9,65.6,274.9,64.7,277,64.1z M269,65
    c1.4-0.4,2.9-0.8,4.3-1.1c0.3-0.1,1.7-0.5,1.8-0.4c0.3,0.4-1.1,0.8-1.4,0.9c-1.3,0.6-2.6,1.2-3.9,1.8c-0.6,0.3-1.1,0.7-1.3,0
    C268.6,66,268.8,65.1,269,65z M267.6,62.5c0.7-0.8,1.3-0.9,2.3-1c0.9-0.2,1.8-0.5,2.7-0.2c-0.6,0.6-1.6,0.9-2.3,1.2
    c-0.4,0.2-1,0.6-1.4,0.6C268.4,63.2,268.1,62.9,267.6,62.5z M276.6,62c-1.1,0.8-1.9,1-3.2,1.3c-1.2,0.2-2.4,0.7-3.6,0.3
    c0.9-0.8,2.3-1.1,3.3-1.5c0.5-0.2,1.2-0.5,1.8-0.5C275.6,61.5,276.1,61.8,276.6,62z M272.1,59.3c0.4,0.3,0.9,0.4,1.4,0.6
    c-2.1,0.8-4.5,1.3-6.8,0.9C268.3,59.4,269.9,58,272.1,59.3z M261.3,55.1c-1,0.3-2.4,1.3-3.3,0.6c0.6-0.3,1.3-0.8,2-1
    C260.7,54.6,260.7,54.7,261.3,55.1z M272.6,41.5c0,0.9-1.2,1.9-2,2.3c-0.6,0.3-0.5,0.4-1.3,0.2c-0.4-0.1-0.7-0.2-1.1-0.3
    C269.7,42.8,271.1,42.1,272.6,41.5z M252.4,42.5c1.5-0.7,3.1-1.4,4.7-2.1c1.2-0.6,3-1.8,4.4-1.5c-0.4,0.8-1.7,1.3-2.5,1.8
    c-1.1,0.6-2.1,1.2-3.2,1.9c-0.4,0.3-0.9,0.5-1.3,0.8c-0.7,0.4-1,0.8-1.7,0.5C252.2,43.7,251.6,42.9,252.4,42.5z M253.4,44.9
    c0.9-0.6,1.9-0.5,2.8-0.3c-0.8,0.5-1.5,1-2.3,1.4c-0.9,0.4-1.8,0.5-2.8,0.8C251.9,46.2,252.6,45.5,253.4,44.9z M267.3,42.9
    c0.3-0.5,0.7-1,1-1.5c-3,1-5.9,2.6-8.7,3.9c-2.7,1.3-5.5,3-8.4,3.5c2.3-1.7,5.2-2.7,7.8-3.9c2.4-1.1,5.2-1.9,7.1-3.7
    c-0.5-0.1-0.9-0.3-1.4-0.3c-0.6,0.1-1.4,0.5-2,0.7c-1.2,0.4-2.4,1.1-3.6,1.2c1.3-1.2,3.6-2.5,5.4-2.8c1-0.2,2,0.2,3,0.5
    c1.1,0.3,2,0.4,3.2,0.4C269.5,41.7,268.5,42.3,267.3,42.9z M252,50.4c1.4-0.7,2.8-1.4,4.1-2.1c0.7-0.4,2.8-1.8,3.5-1.4
    c-0.8,0.8-3.1,3.7-4.1,3.4c0.7-0.6,1.4-1.3,2.1-1.9c-1.1,0.4-2.2,0.6-3.2,1.2c-0.8,0.5-1.8,1.5-2.7,1.1
    C251.8,50.7,251.9,50.5,252,50.4z M258.8,53c-0.5,0.2-1.1,0.6-1.6,0.7c-0.6,0.1-0.8,0-1.4-0.2c0.5-0.3,1.4-1.1,2-1.1
    C258.4,52.3,258.4,52.5,258.8,53z M252.1,52.8c1.3-1,2.9-1,4.5-1.3c-0.1,0.6-1.6,1.2-2.1,1.5c-0.2,0.1-1.1,0.8-1.3,0.8
    C253,53.7,252.4,53,252.1,52.8z M255.5,56.7c-0.3-0.5-0.5-0.9-0.7-1.2c0.9-0.4,2.8-1.7,3.6-1.1C257.5,55.1,256.6,55.8,255.5,56.7z
     M256.2,57.3c1.2-0.3,3.5-1.2,4.7-0.7c-0.3,0.6-1.8,1.1-2.3,1.3C257.5,58.2,257.1,57.8,256.2,57.3z M260.8,57.7
    c1.1-0.5,2.6-1.4,3.8-1.4c1.2,0,2.6,0.6,3.8,0.8c-2.4,0.5-5.6,1.7-8,1.1C260.5,58.1,260.6,57.8,260.8,57.7z M264.1,60.5
    c-1.3-0.4-2.4-0.7-3.4-1.1c2.5-0.5,5.4-1.3,7.9-0.9c-0.4,0.5-1.5,0.7-2.1,1C265.7,59.8,264.9,60.2,264.1,60.5z M266.4,71
    c0.6-1.3,1-3,2.5-3.4c1-0.3,2.1,0.1,3.1,0.1c1.2-0.1,2.3-0.3,3.5-0.2c-1.6,0.6-3.2,1.5-4.8,2.2c-0.9,0.4-1.8,0.9-2.7,1.3
    C267.7,71.2,265.9,72.2,266.4,71z M250.9,73.6c0-1.1,0.5-1.1,1.5-1.5c0.9-0.4,1.3-0.7,2.2-0.2C253.4,72.8,252.5,73.2,250.9,73.6z
     M253.2,74.1c1.6-2.5,5.6-3,8-1.4c-1,0.7-2.7,0.7-3.9,0.9C256,73.8,254.5,74.3,253.2,74.1z M257.4,75.6c-0.8-0.3-1.5-0.5-2.1-0.8
    c1.9-0.4,3.6-0.7,5.5-0.9C260.8,74.5,258,75.4,257.4,75.6z M271.3,75.8c-1.3,0.3-2.6,0.5-3.8,0.8c-1.2,0.3-2,0.3-3.2,0
    c1.2-0.6,2.4-0.7,3.8-0.8c1.1-0.1,2.6-0.6,3.6-0.2C271.5,75.7,271.4,75.8,271.3,75.8z M277.6,71c-0.4,0.5-0.7,1.1-1.2,1.4
    c-0.6,0.4-1.4,0.4-2,0.6c-1.6,0.4-3.2,0.7-4.8,1c-1.5,0.3-3,0.6-4.5,0.9c-1.3,0.3-3.3,1.1-4.6,0.5c1-0.9,2.5-1.2,3.7-1.7
    c1.5-0.6,3-1.1,4.5-1.5s3-0.8,4.5-1.2c1.5-0.4,3-0.9,4.5-1C277.7,70.4,277.7,70.8,277.6,71z M278.1,68.7c-2.1,0.6-4.1,1.2-6.2,1.5
    c1.4-0.7,2.7-1.3,4.2-1.9c0.6-0.2,1.4-0.7,2-0.8c0.9-0.1,1,0.3,1,1.1C278.7,68.6,278.4,68.6,278.1,68.7z'
      />
      <g id='i'>
        <path
          fill='#7269FE'
          d='M238,36.3c1,0,3.4-0.5,4.1,0.6c0.4,0.7,0.3,1.8,0.3,2.5c0.1,1.9,0.1,3.9,0.1,5.8c-0.1,8.3-0.4,16.6,0.3,24.9
      c0.1,1.5,0.7,3.5,0.3,4.9s-2,1.1-3.2,1c-2-0.1-4-0.1-6-0.2c-2.3-0.1-2.1-1.2-1.9-3.3c0.8-8.1,1.5-16.1,1.2-24.3
      c-0.1-2.4-0.2-4.7-0.3-7.1c-0.1-1-0.3-2.2,0-3.2C233.2,35.9,236.4,36.3,238,36.3z M238,60c-1.2,0.5-2.6,0.6-3.1,1.9
      c1.2,0.2,2.6-1.1,3.5-1.9C238.2,60,238.1,60,238,60z M239.6,49.5c0.5-0.2,0.6-1.2,0.9-1.9c-1.2,0.1-1.7,0.4-2.7,1.1
      c-0.7,0.4-2,1.1-2.4,1.8L239.6,49.5z M236.3,64.1c2.3-0.3,4.3-1.4,4.1-4c-1.1,0.2-1.8,1-2.7,1.7c-0.8,0.6-1.7,1.2-2.1,2.2
      C235.9,64,236.1,64.1,236.3,64.1z M235,57.8c0-0.1,0,0.3,0.1,0.7c1.7-0.5,3.8-1.6,4.8-3.1c-0.8-0.3-1.3-0.1-2.1,0.2
      c-0.7,0.2-1.3-0.1-2,0.1C234.8,55.8,235,57,235,57.8z M235.8,73c1.1-0.4,2.3-0.7,3.4-1.1c0.6-0.2,1-0.2,1.2-0.8
      c0.1-0.2,0.1-1,0-1.2c-0.6-0.8-4.8,2.2-5.2,2.9C235.3,72.9,235.4,73,235.8,73z M234.5,43.9c0.4,0.8,0.5,1.2,0.7,1.5
      c0.8-0.4,1.7-0.7,2.4-1.3c0.8-0.6,1.4-1.4,2.1-2.1C237.7,42.5,236.2,42.9,234.5,43.9z M234.8,52.2c0.3,1.1,0.4,1.5,0.5,2
      c1.6-1,3.1-2.1,4.6-3.3C237.9,51,236.5,51.4,234.8,52.2z M240.2,66.3c-0.4-0.3-0.4-0.5-0.9-0.4c-0.6,0.1-1.3,0.9-1.8,1.3
      c-0.4,0.3-2.4,1.4-1.2,1.8C237.5,69.3,240.2,67.5,240.2,66.3z M236.6,38.3c-0.1-0.1-0.1-0.2-0.2-0.4c-0.5,0.3-1.6,0.6-1.8,1.2
      c-0.1,0.4,0.2,1.4,0.5,1.6c1,0.6,3-1.8,3.7-2.4C238,38.3,237.3,38.3,236.6,38.3z M234.7,66.1c0.1,0.6,0.2,1,0.2,1.4
      c1.4-0.6,3.4-1.7,4-3.1c-0.9,0.2-1.8,0.3-2.8,0.5C235.2,65.2,235.1,65.4,234.7,66.1z M240.4,40.7c-0.1-0.7,0-1.2-0.9-1.1
      c-0.4,0-0.7,0.3-1.1,0.5c-0.9,0.6-1.6,1.2-2.3,1.9C237.6,41.8,238.9,41.2,240.4,40.7z M240.8,44.5c-0.1-0.5,0-1.2-0.8-1.2
      c-0.4,0-0.8,0.4-1.1,0.6c-0.8,0.6-2,1.2-2.2,2.2C238.1,45.6,239.4,45.1,240.8,44.5z M238.4,46.4c-0.7,0.3-1.4,0.5-2.1,0.9
      c-0.7,0.4-0.8,0.8-1.2,1.5c1.5-0.2,3.2-0.8,4-2.1C238.9,46.5,238.7,46.5,238.4,46.4z M237.6,59.6c0.6-0.4,1.3-0.7,1.9-1.1
      c0.8-0.5,1-0.7,1.2-1.6c-1.5,0.4-3.3,1.3-4.3,2.5C236.7,59.5,236.9,59.5,237.6,59.6z M239.6,74.3c0.5-0.4,1.1-0.9,1.6-1.3
      c-0.9-0.7-2.1,0.3-3.1,0.7C238.5,73.8,238.9,74,239.6,74.3z M238.1,54.7c0.7-0.3,1.4-0.5,2.2-0.8c-0.5-0.3-0.7-0.7-1.2-0.6
      c-0.5,0.1-1.2,0.7-1.6,1C237.6,54.4,237.8,54.5,238.1,54.7z'
        />
        <path
          fill='#7269FE'
          d='M237.2,20.5c4.5,0,4.6,0.1,4.8,4.7c0,0.7,0.1,1.3,0.1,2c0.1,3.1-0.7,3.6-3.7,3.1c-1.3-0.2-2.6,0-3.8-0.2
      c-0.5-0.1-1.2-0.7-1.3-1.2c-0.2-2-0.1-4-0.3-6s0.6-2.8,2.5-2.5C236.1,20.6,236.7,20.5,237.2,20.5z M238,28.9
      c0.7-0.8,1.4-1.3,1.8-2c0.1-0.3-0.3-0.9-0.5-1.4c-1.2,0.6-2.3,1.3-3.5,1.9C236.3,27.9,236.9,28.2,238,28.9z M240.5,23.7
      c-0.7-0.6-1.1-1-1.5-1.3c-0.5,0.7-1,1.3-1.5,2c0,0.1,0.3,0.5,0.3,0.5C238.7,24.6,239.5,24.2,240.5,23.7z M236.2,22
      c-0.4,0.4-0.8,0.8-1.1,1.3c-0.1,0.1,0.3,0.5,0.4,0.8c0.5-0.5,1-1,1.5-1.6C236.8,22.5,236.6,22.3,236.2,22z'
        />
      </g>
      <g id='l'>
        <path
          fill='#7269FE'
          d='M224.7,69.7c-3-0.8-2.4-4.6-2.9-6.9c-0.6-3.2-1.2-6.4-1.6-9.6c-0.8-6.5-0.8-13.2,0.3-19.7
      c0.5-3.1,1.6-6.2,1.6-9.4c0-1.2,0-3.1-1.2-3.8c-1.2-0.7-3.3-0.8-4.7-1.1c-1.1-0.2-2.9-0.9-3.9-0.5c-1.2,0.5-1.2,2.5-1.5,3.5
      c-0.7,3.3-0.6,6.7-0.8,10c-0.4,7.6-0.8,15.1-1.2,22.7c0.2,0,0.3,0,0.5,0c0,4.2-0.1,8.4,0.1,12.6c0.1,2.9,0.7,5.8,3.6,7.1
      c2,0.8,4.1,1.4,6.2,1.9c1.1,0.3,2.2,0.5,3.3,0.8c0.6,0.1,1.2,0.3,1.8,0.4c0.4,0.1,1.2,0.5,1.5,0.4c1.3-0.4,0.8-4.4,0.9-5.4
      C226.8,71,226.4,70.2,224.7,69.7z M220.5,67.8c-1.5,1.2-2.9,2.4-4.7,3.3c0.9-1.3,1.7-2.5,2.8-3.6c0.3-0.3,0.9-1.1,1.4-0.8
      C220.3,66.7,220.4,67.5,220.5,67.8z M218.5,65.5c-1.3,0-2.3,1.2-3.5,1.5c1.1-1.6,2.8-2.8,3.7-4.4c-1.9-0.3-4,1.3-6,1.5
      c0.6-1.3,1.5-2.3,2.5-3.5c0.4-0.5,1-1.1,1.3-1.7c0.3-0.5,0.2-1.1,0.3-1.7c-0.8-0.1-1.4-0.3-2.2,0c-0.7,0.2-1.3,0.6-1.9,0.9
      c0.7-1,1.4-2,2.3-3c0.8-0.9,1.7-1.6,2.3-2.6c-1.4,0.1-2.8,0.6-4.1,1c1.5-1.5,3-3,4.3-4.8c0.4,1.6,0.6,3.1,0.7,4.7
      c-0.1,0-0.2,0-0.3,0.1c0.5,2.7,1,5.3,1.5,8C219.4,62.8,220.6,65.4,218.5,65.5z M211.8,63c-0.4-1.2-0.4-1.9,0.2-3
      c0.5-0.8,1.4-1.7,2.5-1.9c1.6-0.3,0.4,1.2,0,1.8C213.7,61.1,213,62.1,211.8,63z M217.9,35c0.4,1.9-1.6,4-3.4,4.4
      c-1.6,0.3-0.6-0.8,0.1-1.4C215.5,37,216.6,35.6,217.9,35z M217.8,31.2c0.2-0.2,0.6-0.2,0.9-0.2c-0.2,1.2-0.5,2.2-1.7,2.7
      c-0.9,0.3-1.9,0.4-2.8,0.8L217.8,31.2z M216.7,40.8c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0.3,0.2,0.6,0.4,1.5c-1.1,0.5-2.2,1-3.2,1.4
      C214.3,42.4,215.9,41.5,216.7,40.8z M217.4,46.9c-1.3,0.6-2.5,1.2-3.8,1.7c1.1-1.2,2.1-2.6,3.6-3.4
      C217.2,45.6,217.3,45.9,217.4,46.9z M217.4,23.4c0.5-0.6,2-2.4,2.4-0.9c0.3,0.9,0,2.4-0.1,3.3c-0.2,2.3-2,4.5-4.5,4.4
      c1-1.4,2.1-2.6,3.2-3.9c0.3-0.3,1-1.1,0.8-1.6c-0.2-0.6-1.8-0.5-2.2-0.5C217.1,23.9,217.2,23.6,217.4,23.4z M213.3,21.3
      c0.4-1.1,2.9-0.1,3.6,0.1c-1.2,0.4-2.4,0.6-3.6,1C213.3,22,213.1,21.6,213.3,21.3z M212.3,25.5c0.9-1.1,1.8-2.1,2.7-3.2
      c0.3,0.8,0.5,0.7,1.3,0.6c-1.2,1.5-2.5,3.1-4.2,3.7C212.2,26.2,212.1,25.8,212.3,25.5z M214.1,27.6c0.7-0.1,1.2-0.8,1.7-1.2
      c0.6-0.6,0.9-0.8,1.8-1c-1.2,2.5-3.9,3.8-5,6.3c1,0.2,1.8,0.1,2.8,0c-0.9,1-1.9,1.8-3.1,2.5c-0.6-1.5-0.9-2.7-0.5-4.3
      C212,28.6,212.8,27.9,214.1,27.6z M215.1,35.1c0.2,1-0.2,1.1-0.9,1.7c-0.4,0.4-1.5,1.7-2,1.6c-0.4-0.1-0.6-0.4-0.6-0.7
      c-0.1-0.7,0.2-0.7,0.8-1C213.2,36.1,214.1,35.6,215.1,35.1z M216,39.4c0.1,0,0.3,0,0.5,0c-1.1,2-3.5,3-4.7,4.9
      c1.5,0.5,3.4-0.2,4.9-0.6c-0.5,1.2-1.6,2.1-2.5,3c-0.4,0.5-1.1,1-1.4,1.5c-0.3,0.5-0.3,1-0.4,1.5c0.8,0,1.6,0,2.3-0.1
      c-0.8,1.3-2,2.2-3.1,3.3c-0.2-3.1-0.9-6.7-0.1-9.8C212.1,40.9,213.9,40,216,39.4z M213.5,54c0.4,0.3,0.5,0.4,0.6,0.5
      c-0.8,0.8-1.7,1.6-2.5,2.3c-0.4-0.7-0.6-0.6-0.1-1.2C212,55,212.9,54.5,213.5,54z M212.5,71.3c-0.5-1.6-1.6-3.3-0.8-4.9
      c0.8-1.5,2.8-2,4.2-2.5c-0.9,1.3-1.5,2.5-2.1,4c0.8-0.2,1.6-0.3,2.4-0.6c0.6-0.3,1.3-0.9,2-0.9c-1,1.4-2.1,2.7-3.2,4
      C214.4,71.1,213.1,73.3,212.5,71.3z M213.9,73.5c1.7-1.2,3.9-3.1,6-3.4c-0.6,1.2-1.8,2-2.9,2.8c-0.3,0.3-1.3,1.4-1.7,1.4
      C215,74.3,214.2,73.7,213.9,73.5z M220.8,74.5c-0.4,0.3-2.8,1.6-2.4,0.2c0.2-0.4,1.4-1,1.8-1.2c0.7-0.4,1.1-0.9,1.8-0.6
      C221.6,73.5,221.3,74.1,220.8,74.5z M223.9,75.5c-0.5-0.4-1-0.8-1.5-1.2c0.7-0.8,1.3-1.5,2-2.3c0.3,0.5,0.7,0.9,0.6,1.4
      C225,74.1,224.2,75,223.9,75.5z'
        />
      </g>
      <g id='a1'>
        <path
          fill='#7269FE'
          d='M206.6,71.6c-0.4-0.5-0.6-1.3-1.1-1.7c-0.5-0.4-1-0.3-1.6-0.4c-1.1-0.1-2.2-0.5-3-1.2s-1.1-1.7-1.3-2.7
      c-0.3-1.8-1-3.4-1.1-5.2c-0.3-3.5,0.5-7.1-0.2-10.6c-0.5-2.3-1-4.7-2-6.8c-1.1-2.1-2.8-3.8-5-4.6c-1.3-0.5-2.6-0.6-3.9-0.9
      c-2.1-0.6-4-0.9-6.2-0.9c-1.7,0-3.4,0.3-5.1,0.6c-1.4,0.3-3.1,0.6-4.3,1.4c-2.2,1.4-0.9,4.5-0.7,6.5c0.1,0.8-0.4,2.7,0.6,3
      c0.3,0.1,0.8-0.1,1.1-0.2c1-0.3,1.9-0.7,2.9-1.1c1.8-0.7,3.6-1.3,5.4-2c1.2-0.4,2.7-0.1,3.9,0.5c1.7,0.9,2.3,3.3,2,5.1
      c-0.2,1.4-1.4,1-2.5,0.9c-1.5-0.2-3-0.1-4.5,0.1c-2.7,0.5-5.2,1.9-7.3,3.6c-2.4,2-5,4.4-5.4,7.6c-0.3,3.4,1.6,7.1,2.9,10.2
      c0.7,1.6,1.5,2.4,3.2,2.9s3.3,0.9,5,1.2c3.7,0.6,7.5,0.3,10.8-1.6c1.5-0.9,2.7-1.8,4.3-0.5c1.3,1.1,2.4,2,4.2,2.4
      c1.5,0.3,3.1,0.2,4.7,0.1c0.9,0,1.9,0,2.7-0.4c0.7-0.3,1-0.9,1.2-1.6C206.2,74.5,206.9,72,206.6,71.6z M191,59.3
      c0.9-0.4,2-0.6,2.9-0.9c-0.3,1.7-2.1,2.9-3.4,3.9C190.2,61.3,189.6,59.9,191,59.3z M189.7,58c0-0.2-0.1-0.4-0.1-0.6
      c0.8-0.3,1.7-0.7,2.5-1c0.7-0.3,1.3-0.7,0.9,0.2C192.5,57.7,190.7,58.2,189.7,58z M190.6,63.5c0.7-0.2,2.6-0.8,3.2-0.3
      c-1,0.5-2.2,1.3-3.3,1.3C190.6,64.2,190.5,63.6,190.6,63.5z M193.4,62.1c-0.1,0-0.3-0.1-0.4-0.2c0.7-0.9,1.3-1.7,2-2.6
      c0.3,0.3,0.5,0.6,1.2,1.2C195,61.2,194.2,61.7,193.4,62.1z M193.4,43c0,0.6,0.2,1-0.2,1.4c-0.6,0.5-1.8,0.6-2.6,0.8
      c0.5-0.5,1-1,1.5-1.5C192.1,43.8,193.4,42.3,193.4,43z M187.8,45.5c0,0-0.2-0.4-0.2-0.5s1.1-0.6,1.2-0.7c0.9-0.5,1.7-0.7,2.7-1
      c0,0.1,0.1,0.2,0.1,0.3C190.3,44.3,189.1,44.9,187.8,45.5z M189.4,51.3c-0.2,0.1-0.6-0.1-0.8-0.2c0.7-0.8,1.6-1,2.6-1.4
      c0.3-0.1,1.8-0.9,1.9-0.6C193.1,49.4,189.4,51.3,189.4,51.3z M176.7,40.5c1-0.6,1.9-1.2,2.9-1.8s1.1-0.7,2.1-0.3
      c-0.7,0.7-1.7,1-2.6,1.5c-0.8,0.4-1.7,0.9-2.5,1C176.6,40.8,176.6,40.6,176.7,40.5z M172.9,40c0.9-0.4,2.4-0.9,3.4-0.7
      c-0.4,0.7-1.1,1-1.7,1.5c-0.9,0.6-1.3,0.6-2.3,0.6C172.4,40.9,172.5,40.2,172.9,40z M179.6,43.4c-1.2,0.1-2.3,0.6-3.3,1.1
      c-1.2,0.6-2.1,0.9-3.4,0.9c0.3-1.1,0.1-2.1,1.2-2.7c1.2-0.6,3.2-1.4,4.5-1.5c1.2-0.1,0.2,1.2,1.2,1.4c1.1,0.2,2.4-1.3,3.5-1.5
      C183.2,41.2,181.2,43.2,179.6,43.4z M181.4,40.9c0,0-0.2-0.1-0.3-0.1c0.4-0.5,0.9-1.4,1.5-1.7c0.5-0.3,0.8,0,1.4,0.1
      C183.1,39.9,182.2,40.4,181.4,40.9z M185.1,41.5c0.2-0.6,1.9-1.4,2.5-1.6c1.3-0.4,2.4,0.2,3.5,0.8c1.2,0.7,1.9,1.1,0.3,1.7
      c-1.3,0.5-2.7,1.1-4.1,1.3c0.5-1,1.2-1.8,1.7-2.8C187.7,40.6,186.3,41.2,185.1,41.5z M187.4,55.4c1.4-0.5,2.1-2,3.5-2.4
      c0.6-0.2,1.2-0.2,1.9-0.2c1.1,0.1,1.1,0,2-0.5c-1.4-0.7-2.7-0.4-4.2-0.3c0.7-0.6,1.3-1.1,2-1.6c0.7-0.6,1-1.3,1.5-2.1
      c-0.5,0-1.5,0.2-1.9,0.1c-0.3-0.1-0.3-0.6-0.6-0.7c-0.7-0.3-1.3,1-2,0.8c-0.3-0.1-0.6-0.7-0.6-1c0.1-0.4,0.4-0.4,0.8-0.6
      c0.6-0.2,1.2-0.5,1.8-0.4s1.2,0.5,1.8,0.4c0.9,0,1.5-0.7,2,0.4c0.4,1,0.3,2.5,0.4,3.6c0.1,1.5,0.2,3.1,0.3,4.6
      c0.1,1.4-0.5,1.9-1.8,1.6c0.6-0.7,1.1-1.3,1.6-2.1c-0.8-0.4-1.7-0.8-2.6-0.6c-1,0.2-2.1,0.9-3.1,1.3c-2.1,0.7-4.3,0.7-6.4,1.1
      C184.8,56,186.2,55.8,187.4,55.4z M188.1,62.7c0,2.3-0.3,4.9-2.8,5.7c-1.9,0.6-5.7,0.8-6.1-1.9c-0.4-2.9,2.2-6.1,4.5-7.5
      c0.9-0.6,2.6-1.6,3.7-1C188.6,58.8,188.1,61.5,188.1,62.7z M180.5,54.1c1.3-0.8,2.6-1.6,4-2.4c0.4,0.7,1.2,0.6,2,0.8
      c-2,0.7-4,1.8-6.1,2.2C180.4,54.5,180.3,54.2,180.5,54.1z M171.8,60.1c0.3-0.7,0.5-1.5,0.8-2.2c0.4-0.7,1.1-0.9,1.9-1.3
      c1.3-0.6,2.9-1.8,4.3-1.6c-0.4,1.6-2.3,2.7-3.6,3.7c-0.7,0.6-1.4,1.2-2.1,1.7c-0.2,0.1-0.8,0.6-1,0.6s-0.7-0.5-0.9-0.6
      C171.4,60.4,171.7,60.3,171.8,60.1z M171.9,69.8c-0.3,0.2-0.6,0.5-0.9,0.7c0.2-1.1,0.2-1.4,1.1-2.1c0.8-0.6,1.3-0.9,1.9-1.8
      c-0.8,0.3-1.5,0.6-2.3,0.8c1.6-1.4,4.4-2.6,5.2-4.6c-1.2,0.4-2.4,0.8-3.6,1.2c0.9-1.3,1.2-2.1,2.8-2.7c0.6-0.3,1.3-0.4,1.9-0.8
      c0.5-0.3,0.7-0.8,1.1-1.3c-1.5,0.3-2.5,0.7-3.8,1.4c1.3-1.3,2.6-2.3,4.1-3.3c1.4-1,3-2.4,4.6-2.7
      C180.1,59.7,176.8,65.6,171.9,69.8z M175.2,73.4c-0.4,0.4-1.8-0.1-2.7-0.2c0.9-1.5,1.8-2.5,3.2-3.6c-0.1-0.1-0.1-0.2,0-0.3
      c-0.3,0.4-1.4,0.4-1.8,0.5c0.4-0.5,1.2-2,2-2.2s1.3,1.3,1.5,1.8C178.1,71.6,176.5,72.1,175.2,73.4z M179.6,74.5
      c-0.2,0.2-0.8-0.1-1.2-0.2c0.3-0.6,0.6-1.2,1-1.7c-0.7-0.1-1.3-0.1-2-0.1c0.5-0.8,0.9-1.8,1.8-2.1c0.8-0.3,1.9,0.2,2.8,0.4
      c1.3,0.3,1.4,0.5,0.3,1.4C181.4,73,180.5,73.8,179.6,74.5z M187,69c0.8-0.6,1.6-1,2.1,0c0.3,0.7,0.1,0.7,1,0.7
      c0.8,0,1.6,0,2.4-0.1c-1.5,1.5-2.6,3.3-4.5,4.3c-1.5,0.8-3.7,1.4-5.4,1c0.9-1,2.2-1.7,3.3-2.5s2-1.3,2.5-2.6
      c-1.6,0.4-2.7,1.8-4.2,2.1 M193.1,67.4c-0.6,0.5-1.5,1-2.3,0.8c-0.5-0.1-0.5-0.2-0.6-0.6c-0.1-0.6,0.1-0.6,0.7-0.9
      c1.5-1,3.1-1.2,4.9-1.4C194.8,65.9,194,66.7,193.1,67.4z M196.1,71.2c0.7-0.9-0.2-1.7-1.1-1.9c0.7-0.5,1.3-1,1.9-1.6
      c-0.3-0.2-1.4-0.8-1.4-1.2c-0.1-0.3,0.6-0.7,0.8-0.9c0.7,0.6,1.1,1.2,1.6,2c0.4,0.6,1.3,1.2,1.5,1.8
      C199.8,70.5,196.9,71.1,196.1,71.2z M197.8,74.6c-0.5,0,0.7-1.6,0.7-1.7c0.6-0.9,0.7-1.3,1.8-1.2
      C199.7,72.9,198.7,73.7,197.8,74.6z M203.9,74.4c-0.4,1-3,0.6-3.7,0.5c1.3-1.4,2.5-2.5,4.1-3.5C204.2,72.4,204.3,73.5,203.9,74.4z
       M169.4,62.5h2.2v2.4l-2,2v-2.5L169.4,62.5z'
        />
      </g>
      <g id='c'>
        <path
          fill='#7269FE'
          d='M141.2,56.7c-1.1,0.7-2.1,1-3.4,1.4c-0.1-0.8,0.7-0.8,1.4-1.1C139.9,56.8,140.5,56.4,141.2,56.7z'
        />
        <path
          fill='#7269FE'
          d='M163.7,67.2c-0.8-2.7-2.8-0.3-4.3,0.4c-1.8,0.8-3.8,1.1-5.7,0.7c-4.3-0.8-6.9-4.1-7.5-8.2
      c-0.7-4.7-0.5-10.4,4.8-12.3c2.3-0.8,4.9-1.3,7.3-0.8c1.3,0.2,2.4,0.8,3.4,1.5s2.2,1.1,2.4-0.4c0.2-1.4,0.1-3,0-4.4
      c-0.1-1.5-0.7-1.9-1.9-2.7c-2-1.3-3.9-2.5-6.2-2.9c-3.3-0.7-6.9-0.4-10.1,0.6c-3.4,1.1-6.6,3.1-8.8,6c-2.7,3.7-2.7,7.8-2.9,12.2
      c-0.1,3.1-0.1,6.3,1,9.2c0.9,2.4,2.6,4.7,4.5,6.4c1.8,1.6,4,2.8,6.3,3.5c1.2,0.4,2.5,0.6,3.7,0.7c0.6,0.1,1.2,0.1,1.8,0.1
      c0.9,0,1.3-0.2,1.6,0.7c3.4-0.9,7.1-1.5,10.2-2.9C166.4,73.5,164.4,69.4,163.7,67.2z M161.8,45.6c0.2,0.9-1.9,0.4-2.3,0.4
      c-1-0.1-1.9-0.2-2.8-0.5c0.8-1.2,2.3-1.4,3.7-1.7c0.7-0.2,0.6-0.2,1,0.3C161.6,44.4,161.7,45.2,161.8,45.6z M154.4,40.9
      c0.7-0.7,1.5-1,2.5-0.7c1.1,0.3,1.7,0.8,0.4,1.4c-1.1,0.5-2.2,1-3.3,1.4c-0.9,0.4-1.7,0.9-2.7,0.7
      C152.3,42.6,153.5,41.9,154.4,40.9z M149.1,41.2c1-1.2,2.5-1.6,4-1.1c-0.6,0.7-9.1,5.4-9.3,5.1C143.7,45.1,148.7,41.6,149.1,41.2z
       M139.8,44.9c0-0.8,2.7-2,3.3-2.4c0.7-0.4,1.3-0.8,2-1.3c0.4-0.2,1.3-1.1,1.8-1c1.4,0.3-1,1.7-1.3,1.9c-1,0.6-2.7,2.2-3.8,2.2
      c0.1-0.3,0.5-0.9,0.5-1 M136.4,54.6c0.2-0.4,0.6-0.5,0.9-0.8c0.5-0.4,0.9-0.9,1.4-1.4c-0.7,0.2-1.5,0.5-2.2,0.3
      c0.3-0.9,0.2-2.1,0.8-2.9c0.7-0.9,2.4-1.5,3.5-2c1.2-0.6,2.5-1.2,3.7-1.9c1.1-0.5,2.5-1.6,3.7-1.6c-0.4,0.6-0.9,1.2-1.4,1.8
      c3.2-0.4,6-2.1,9.2-2.6c-1.1,1.2-2.8,1.6-4.4,2.2c-1.6,0.6-3.6,1.1-5.1,2c-0.5,0.3-0.8,0.8-1.2,1.1c-0.6,0.5-1.5,0.9-2.2,1.3
      c-1.3,0.8-3.2,2-3.7,3.5c1.1-0.3,1.9-1,2.8-1.7c0.9-0.6,1.7-1,2.8-1.4c0,1.1-0.8,1.5-1.6,2.3c-0.1-0.1-0.2-0.2-0.3-0.4
      c-2.1,1.5-4.6,2.7-7,3.6C136.3,55.6,136.2,55,136.4,54.6z M139.2,55.7c1.4-0.9,3.5-1.8,5.2-1.9c-0.6,1.7-2.6,3.1-3.8,4.4
      c1.3,0,2.1-0.8,3.4-0.5c-1,1.5-3.3,2-5,2.3c0.3-0.4,0.6-0.8,0.7-1.2c-1.2-0.1-2.4,1-3.5,1.4c0-0.5-0.1-0.7-0.4-1
      C136.8,57.9,137.8,56.6,139.2,55.7z M135.5,62.1c1.2-0.7,2.2-1.2,3.6-1.5c1.4-0.3,2.9-0.9,4.4-0.9c-0.9,1.3-3,2.1-4.4,2.8
      c-0.5,0.2-1.7,1.2-2.2,1.1C136.5,63.6,135.8,62.4,135.5,62.1z M139.7,65.7c1.8-0.8,3.2-1.5,4.7-2.7c-1.5,0.2-2.5,0.3-3.8,1
      c-1.2,0.6-2.3,1.4-3.6,1.6c0.7-1.7,2.8-2.6,4.4-3.4c1.1-0.6,2.5-1.8,3.2-0.3c0.7,1.3,1.8,3.3,1.7,4.8s-1.4,2.5-2.8,2.8
      c0.2-0.3,0.4-0.6,0.7-0.9c-0.3,0-0.6,0-0.9,0c0.1-0.6,0.3-1,0.6-1.5c-1.3,0.2-2,1.2-3.2,1.6c-1.4,0.4-1.8-0.1-2.3-1.2
      c0.9-0.4,1.8-0.9,2.7-1.3 M157.3,73.9c1.1-1.5,3.1-2,3.9-3.7c-1.2-0.2-1.2-0.1-2.1,0.8c-0.7,0.6-1.4,1.2-2,1.9
      c-1.3,1.4-2.3,2.6-4.3,2.7s-4.2-0.8-6.2-1.4c-0.5-0.2-1-0.3-1.5-0.5c-0.2-0.1-1.4-0.3-1.5-0.5c-0.5-0.6,0.3-1.4,0.6-1.8
      c-0.6-0.4-0.9-0.5-0.5-1s1.7-0.9,2.3-1.2c0.5-0.3,1.5-1.1,2.1-1.1c0.4,0,1.1,0.5,1.5,0.7c-0.5,0.4-1.1,0.8-1.6,1.2
      c1.4,0.9-0.1,2.1-0.9,3c1.8-0.3,4.2-0.3,5.1-2.1c-0.6,0-1.2,0-1.8-0.1c0.8-1,1.6-0.9,2.9-1.1c-0.4,0.8-0.7,1.8-1.3,2.4
      c-0.6,0.7-1,0.9-1.5,1.7c1.2,0.4,1.6,0.6,2.6-0.3c0.8-0.8,1.5-1.6,2.3-2.3c1.4-1.2,3.5-2.1,5.3-2.5c0.8-0.2,1-0.1,1.3,0.5
      c0.2,0.5,0.6,1.6,0.3,2.1c-0.3,0.6-2,1.3-2.6,1.5C159.2,73.3,158.1,74.1,157.3,73.9z M161,74.7c-0.6-0.2-0.7-0.3-0.9-0.4
      c0.3-0.2,2.3-1.8,2.6-1.6C163.2,73.1,161.3,74.4,161,74.7z'
        />
      </g>
      <g id='u'>
        <path
          fill='#7269FE'
          d='M104.5,78.8c-15.4-3.3-14.7-21.7-14.7-34c0-1.9-0.2-3.8,0-5.7c0.1-1.4,0.7-2,2.2-2.1c1.9-0.1,3.7,0.2,5.6,0.1
      c1.2-0.1,2.9-0.5,3.9,0.4s0.6,2.5,0.4,3.6c-0.3,2.1-0.5,4.3-0.7,6.5c-0.3,3.6-0.1,7.3,0.6,10.8c0.6,3.2,2.2,6.6,5.2,8.3
      c1.9,1.1,5.3,1.3,7.4,0.4c3.1-1.2,1.4-4.9,1-7.3c-0.7-4-0.7-8.2-0.7-12.3c0-1.8,0-3.7-0.1-5.5c-0.1-1.1-0.3-2.1-0.2-3.2
      c0.2-3.4,3.2-2.4,5.6-2c1.4,0.2,2.8,0.3,4.1,0.6c1.6,0.4,2.1,1.4,2.1,3c-0.1,3.8-0.2,7.7-0.2,11.5c0,2.2,0,4.5,0.3,6.7
      c0.3,2.1,0.8,4.2,1.1,6.3c0.3,2.1,0.5,4.3,0.6,6.4c0.1,1.5,0.7,3.9,0.1,5.4c-0.8,1.8-3,1.3-4.6,1.2c-2.3-0.1-4.6-0.4-7-0.3
      c-2,0-3.7,0.8-5.6,1.2C108.9,79.2,106.6,78.8,104.5,78.8z M94.6,67.7c-0.1,0.1-0.1,0.4-0.1,0.6c1.4,0,2.6-1.5,3.7-2.3L94.6,67.7z
       M119,59.3c1.2-0.1,2.7,0.1,3.8-0.4c1.1-0.5,0.9-1.6,0.9-2.6C122,57.1,120.5,58.2,119,59.3L119,59.3z M120.1,68.2
      c1.3-0.3,2.9-0.4,4.1-1c0.9-0.4,0.9-1.1,1.2-2c-2,0.1-3.9,1.6-5.5,2.9c-1.5,1.2-3.3,2.5-4.2,4.2c0.9,0.3,1.4,0.7,2.4,0.4
      c0.8-0.3,1.6-0.9,2.4-1.3c1.6-1,3.2-2,4.7-3.2c-1.8,0.2-3.4,0.2-5.3,0.1C120,68.3,120,68.2,120.1,68.2z M94.5,66
      c0.1,0.4,3.5-0.8,3.8-1c0.7-0.3,1.5-0.6,2-1.2c0.8-1.1,0-2.7-0.4-3.8c-0.2-0.6-0.3-1.9-0.9-2.3c-0.8-0.7-1.6,0.5-2.2,1
      c-0.3,0.3-3.2,2.3-3,2.6s2.1-0.6,2.3-0.6c0.7-0.2,2.7-0.9,3.1-0.1c0.2,0.5-0.7,1.6-1,1.9c-0.5,0.6-1.2,1.1-1.8,1.6
      C96.1,64.5,94.4,65.4,94.5,66z M105.2,75.6c0,0.1,2.4-1.2,2.7-1.4c2.2-1.3,4.3-2.6,6.5-3.9s4.3-2.7,6.5-4c0.4-0.3,1.7-0.7,1.8-1.3
      c0.2-0.8-0.9-0.7-1.4-0.8c0.8-0.4,2-0.5,2.8-0.9c0.9-0.5,0.5-1.3,0.3-2.1c-2.1,0.8-3.9,2.4-5.8,3.7c-1.6,1.1-1.9,2.9-3.6,3.9
      c-1.8,1.1-3.5,2-5.2,3.2c-1,0.7-2,1.4-2.9,2.2C106.8,74.3,105.2,75.2,105.2,75.6z M105,68.7c-2.7,1.4-5.4,2.7-7.9,4.4
      c1.6,1.5,3.6,3.9,6,2.7c1.2-0.6,2-1.8,3-2.6c1-0.9,2.3-1.6,3.1-2.8c-1.6,0.2-2.9,1.3-4.2,2c-1.6,0.8-2.9,1.4-4.7,1.7
      c0.8-1.3,1.3-1.9,2.5-2.7c1.1-0.7,2.2-1.2,2.8-2.4C105.5,68.9,105.4,68.9,105,68.7z M121,76.8c1.3-0.1,3.4,0.3,4.6-0.4
      c1-0.6,0-2.6-0.2-3.5c-2.5,0.8-4.7,2-7.1,3.2C119.4,76.4,120.5,76.7,121,76.8z M99.7,41.1c0-1.2,0.2-2.2-1.3-2.1
      c0.2,0.6,0.9,1.1,0.8,1.7c0,0.7-1,1.3-1.4,1.7c-1.6,1.4-3.1,2.8-4.7,4.1c1.6-0.3,4.1-0.3,5.4-1.4C99.7,44.2,99.6,42.5,99.7,41.1z
       M116.7,53.1c0,0.2,0.1,0.8,0.2,1.5c2.2-1.2,4.1-2.7,5.4-4.8c-0.5,0.1-1.1,0-1.6,0.2c-0.4,0.1-0.7,0.5-1,0.6
      c-0.9,0.3-1.3-0.6-2.1-0.5C116.4,50.3,116.8,52.3,116.7,53.1z M122.1,59.8c-1.1,0.1-2.9,0-3.9,0.7c-0.9,0.7-0.1,1.9,0.1,2.8
      c2-0.7,3.6-1.6,5.1-3.2C122.9,60,122.4,59.9,122.1,59.8z M99.1,48.7c-0.2-1-0.3-1.5-0.4-2c-2,1.1-4.3,3.2-5.5,5.1
      C95.3,51.5,97.5,50,99.1,48.7z M99.4,53.5c-0.2-1-0.3-1.4-0.4-1.8c-2.1,0.5-4,2.8-5.2,4.4C96,55.5,97.7,54.9,99.4,53.5z
       M116.4,43.4c0.2,0.3,0.4,1.1,0.8,1.2c0.5,0.2,1.2-0.5,1.6-0.8c1.3-0.8,2.9-1.4,3.8-2.7c-1.3-0.2-2.5,0.4-3.7,0.6
      C117.6,42.1,117.2,42.4,116.4,43.4z M116.5,57.3c0.3,0.3,0.5,1,0.9,1.2c0.6,0.3,1-0.1,1.6-0.4c1.1-0.7,2.5-1.2,3.2-2.4
      C120.3,55.2,118,56.1,116.5,57.3z M92.7,58.1c0.1,1.1,0.1,1.5,0.2,1.9c1.8-1.6,3.8-2.9,5.7-4.4c-1.1,0.3-2.3,0.3-3.4,0.8
      C94.2,56.9,93.5,57.5,92.7,58.1z M108.9,77.3c0.5-1.3,1.1-2.3,1.9-3.4c-2.3,1.1-4.6,2.2-6.9,3.4C105.3,77.3,106.7,77.3,108.9,77.3
      z M116.5,47c0.4,1,0.3,2.2,1.7,1.7c1.4-0.5,2.4-1.8,3.5-2.8C119.7,46,118.1,46,116.5,47z M123.6,53c-0.1-0.7-0.3-1.3-0.4-2
      c-1.4,1.3-2.7,2.5-4,3.9C120.6,54.7,124,55.3,123.6,53z M93.5,65.1c1-0.7,1.9-1.5,2.9-2.2c0.8-0.6,1.8-1.1,2.2-2
      c-1.3-0.2-3.1,1.1-4.3,1.6C92.8,63,92.8,63.8,93.5,65.1z M125.8,71.2c-0.2-0.6-0.3-1-0.4-1.4c-2.2,1.4-4.5,3-6.5,4.8
      c1.4-0.2,2.6-0.9,3.8-1.5C123.8,72.5,125,72.1,125.8,71.2z M97.9,71.3c1.6-0.8,3.2-1.6,4.8-2.3c0.4-0.2,2.8-1.4,1.3-1.9
      c-0.9-0.3-2.3,1.3-2.9,1.7C100.1,69.5,98.2,70.1,97.9,71.3z M96.2,70.7c1.1-0.7,2.3-1.5,3.4-2.2c0.8-0.5,1.9-0.9,2.3-1.7
      c-1.6,0.1-3,1.1-4.4,1.7C96.2,69,95,69.3,96.2,70.7z M92.3,43.9c0.1,0.5,0.2,0.9,0.3,1.2c1.3-0.5,3.6-1.9,4.1-3.3
      C95,42.3,93.5,42.5,92.3,43.9z M92,47.9c0.3,1.1,0.4,1.6,0.5,2c1.3-0.6,3.2-1.8,3.5-3.3C94.7,47.1,93.4,47.5,92,47.9z M123.6,47.2
      c-0.7-0.8-0.8-0.8-1.7-0.1c-0.8,0.6-1.8,1.1-2.1,2c0.9-0.1,2,0.1,2.8-0.3C123.4,48.4,123.4,47.8,123.6,47.2z M92.7,53.6
      c0.1,0.7,0.2,1,0.3,1.4c1.7-1.1,3.2-2.6,4.6-4C95.9,51.7,94.1,52.4,92.7,53.6z M94.1,38.8c-0.6,0.2-1.2,0.1-1.6,0.7
      c-0.4,0.6,0,1.4,0,2c1.3-0.7,2.4-1.4,3.5-2.4C95.5,38.9,95,38.9,94.1,38.8z M122.4,44.7c0.5-0.3,1-0.3,1.3-0.9
      c0.3-0.5,0.1-1.1,0.2-1.6c-1.4,0.7-2.5,1.3-3.7,2.4C120.8,44.6,121.3,44.6,122.4,44.7z M115.2,74c-0.5-0.7-0.7-0.8-0.8-1
      c-1.1,0.8-2.1,1.7-3.2,2.5C111.8,76.1,114.7,74.2,115.2,74z M121.2,40.5c0.8-0.3,1.7-0.7,2.5-1.1c-1.3-0.3-2.4,0.2-3.7,0.5
      C120.4,40.1,120.7,40.3,121.2,40.5z M118.8,38.4c-0.8,0.5-1.5,1-2.2,1.4c0.7,0.9,2-0.3,2.8-0.7C119.2,39,119.1,38.8,118.8,38.4z
       M107.7,70.3c-0.7-0.5-1.3,0.2-2,0.6c-0.4,0.2-1.7,0.6-1.5,1.2C105.4,71.5,106.5,70.9,107.7,70.3z'
        />
      </g>
      <g id='d'>
        <path
          fill='#7269FE'
          d='M70.2,72.1c-0.8,0.3-1.7,0.7-2.5,1c0.6-0.5,1.3-1,1.9-1.6c0.6-0.7,1.3-1.4,1.6-2.2c0.5-1.3,2.6-2.2,3.9-1.6
      c0.1,0,0,0.4,0,0.5c-1.7,0.6-2.9,1.4-2.7,3.5c0,0.4-1.2,1-1.8,1.5c-0.1-0.3-0.2-0.6-0.3-0.9c0.1-0.1,0.2-0.3,0.3-0.4
      c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.2,0.1-0.2,0.1C70.5,71.9,70.4,72,70.2,72.1z'
        />
        <path
          fill='#7269FE'
          d='M78.8,67.6c-1-0.1-1.7-0.2-2.4-0.3c0.9-0.3,1.7-0.6,2.6-0.9c0.1,0,0.4,0.4,0.5,0.6
      C79.3,67.2,79,67.5,78.8,67.6z'
        />
        <path
          fill='#7269FE'
          d='M79.9,62c-1.1,0.5-2,0.9-2.9,1.3c-0.1,0-0.2-0.1-0.3-0.2c0.7-0.5,1.4-1.1,2.2-1.5C79,61.4,79.4,61.7,79.9,62z
      '
        />
        <path
          fill='#7269FE'
          d='M82.8,74c-0.2-2.2-0.6-4.5-0.6-6.7c0.1-8.3,0.4-16.7,0.5-25c0.1-5.8-0.1-11.6-0.3-17.4
      c-0.2-4.7-2.5-5-7.8-4.5c-1.3-0.1-1.7,0.4-1.9,1.6c-0.3,2.8-1,12.9-0.7,19.7c0,0.7-0.7,0.7-0.8,0.5c-1.8-2.8-4.6-3-7.6-3.3
      c-3.9-0.4-7.2,1.1-10.7,2.4c-3.9,1.5-5.3,5.3-7.1,8.3c-1.7,2.8-1.6,6.9-1.4,10.4c0.2,5.2,4,8.6,7.3,12.3c3.8,4.2,8.4,5.9,13.9,5.1
      c5-0.7,10.1-1.1,15.2-1C82.5,76.4,82.9,75.5,82.8,74z M80.8,35c-1,1.3-3.7,2.5-5.4,2.6c0.7-0.5,3.6-2.6,4.4-3.3
      c0.2-0.2,0.3-0.7,0.4-1.1c-0.4-0.1-0.8-0.3-1.1-0.2c-0.8,0.2-2.5,1.2-3.2,0.8c0.4-1,2-1.5,2.7-2c0.3-0.3,0.5-0.8,0.7-1.2
      c-0.4-0.1-0.8-0.3-1.3-0.3c-0.3-0.1-0.6,0-1,0c0.2-0.3,2-1.6,2.4-2.5c-0.6-0.2-1.1-0.4-1.7-0.5c-0.4,0-0.8,0.1-1.2,0.2
      c0.2-0.3,3.1-4.9,3.6-4.7C80.7,22.8,81.7,33.8,80.8,35z M75.4,38.5c0.8-0.3,1.8-0.4,2.7-0.6c-0.7,0.6-2.7,2.4-3,2.7
      c0.1,0.1,0.2,0.2,0.3,0.3c1.6-0.9,4.7-2.9,5.5-2.6c0.1,0.8,0.1,1.6,0.2,2.4c0,0-4,1.1-5.7,1.6c-0.1,0-0.2,0.9-0.5,1.1
      C73.5,42.5,73.6,39,75.4,38.5z M74.3,35.8c0.9-0.5,2.8-1.6,3.3-0.9c-1.3,0.8-2.4,1.6-3.5,2.3C73.7,36.8,74,36,74.3,35.8z
       M74.4,30.5c-0.1,0.1-0.3-0.1-0.5-0.1c0.2-0.3,0.3-0.9,0.6-1l2.9-1.1C76.4,29,75.4,29.8,74.4,30.5z M74.5,25.2
      c0.2-0.2,1.1,0.3,1.7,0.5c-0.7,0.7-1.3,1.5-2,2.2c-0.1-0.2-0.3-0.5-0.4-0.8C74,26.5,74.1,25.7,74.5,25.2z M76.9,31.3
      c0,0.1,0.1,0.2,0.1,0.2c-1,0.7-1.9,1.4-2.9,2.1c-0.1-0.2-0.2-0.5-0.4-1.1C74.8,32.1,75.8,31.7,76.9,31.3z M62.1,68
      c-2.7-0.1-4.5-3.6-3.6-6c0.1-0.2,0.1-0.5,0.1-0.6c-2.6-3.4-1.2-6.8,0.3-9.9c1-2.2,2.9-3.8,5.7-4.2c2-0.2,3.2,0.6,4.5,2
      c2.1,2.4,3,5.8,2.9,10.5C71.7,67.6,69.5,68.2,62.1,68z M64.4,69.6c-0.1,0.2-0.2,0.6-0.4,0.7c-1.5,0.6-3,1.2-4.4,1.8
      c-0.4-0.2-0.6-0.2-0.7-0.3c0.1-0.1,0.2-0.3,0.4-0.4c1.5-0.6,3-1.2,4.5-1.8C63.9,69.5,64.1,69.6,64.4,69.6z M57.1,46.6
      c-0.7-0.1-1.6-0.2-2.4-0.4c1-0.5,2-1.3,3-1.4c0.6-0.1,1.3,0.9,1.9,1.5C58.9,46.4,58.1,46.5,57.1,46.6z M67.6,45.7
      c-1.2,0.5-2.4,0.8-3.7,1.2c-0.3,0.1-0.6-0.2-0.9-0.2c0.2-0.2,0.3-0.5,0.6-0.6c1.3-0.5,2.5-1,3.6-1.4c0.3,0.1,0.4,0.2,0.5,0.2
      c0.6-0.1,1.2-0.2,1.8-0.3C68.9,45,68.3,45.5,67.6,45.7z M68.1,41.2c0.1-0.1,0.6,0.1,0.6,0.2c0.1,0.3,0.1,1,0,1
      c-2.5,0.9-5,1.8-7.7,2.7c-0.2-0.3-0.4-0.5-0.6-0.8c0.2,0,0.4-0.1,0.7-0.1c0.3-0.1,0.5-0.2,0.8-0.3C63.9,43,66,42.1,68.1,41.2z
       M49.5,46.7c3.1-3.5,7-5.8,11.6-6.6c0.5-0.1,1.2,0.4,1.8,0.7c-0.4,0.3-0.8,0.7-1.2,0.9c-4,1.7-8,3.5-12,5.2
      c-0.1,0-0.1,0.1-0.2,0.1C49.5,46.8,49.5,46.7,49.5,46.7z M46.7,56.1c0.2-0.2,1,0,1.4-0.1c0.2,0,0.8-1.1,1-1.2
      c1.8-0.9,7.3-2.8,7.1-4c-1-1.7-9.1,5.5-10.1,4.3c-1-1.1,3.4-8.5,6.2-7.9c-0.4,1.4,0.6,0.6,1,0.6c1.5-0.1,4.8-1,5-0.5
      c0.3,0.5-9.9,3.9-9.7,4.4s9-3.1,9.3-2.6s-2.2,4-3.8,5.3c-2.5,2.1-5.6,2.4-8.1,4c-0.1,0.1-0.3-0.1-0.5-0.2
      C46,57.6,46.2,56.7,46.7,56.1z M56.8,66.1c-0.3,0.5-0.7,1-1.1,1.4c-0.9-0.5-1.5-0.8-2.1-1.1c0.8-0.3,1.7-0.7,2.5-1
      C56.3,65.4,56.9,66,56.8,66.1z M46.3,61.7c-0.9-1.1-0.7-2,0.7-2.5c0.9-0.3,1.7-0.6,2.6-0.9c0,0.2,0.1,0.3,0.1,0.5
      c1.5-0.3,2.9-0.7,4.4-1c0.3-0.1,0.6,0,0.9,0C54.9,58.1,47.6,63.8,46.3,61.7z M53.8,59.8c1.5-1,1.9-0.3,1.9,1.8
      c-1.3,1.4-4.8,2.6-6.4,3.1c-0.6,0.2-1.4-0.2-2.1-0.4C47.6,63.2,52,61,53.8,59.8z M51.5,68.7c-0.9-0.8-1.8-1.6-2.8-2.4
      c0.2-0.1,0.3-0.3,0.5-0.4c0.6-0.2,1.2-0.3,1.8-0.4c0.5-0.1,1,0,1.5-0.1c-0.3,0.4-0.7,0.8-1,1.2c-0.2,0.3-0.5,0.5-0.7,0.8
      c0.3,0.1,0.5,0.1,0.8,0.2c0-0.1,0-0.2,0-0.3c1.9,0.2,3.9,0.3,5.8,0.5c0.1,0,0.2,0.1,0.4,0.2c-0.1,0.1-0.3,0.2-0.4,0.2
      c-1,0.3-2,0.5-3,0.8c-0.2,0-0.5,0-0.5,0C53.3,66.6,52.2,69.4,51.5,68.7z M52.9,70.9c0.2-0.4,0.4-0.9,0.7-1c1.3-0.4,2.7-0.7,4.1-1
      c0.1,0,0.2,0,0.4,0c0.5,0.1,1,0.1,1.5,0.2c0.3,0.1,0.7,0.1,1,0.2c-0.3,0.2-0.5,0.4-0.8,0.5c-1.1,0.3-2.2,0.5-3.2,0.7
      c-0.1-0.1-0.2-0.2-0.2-0.2c-0.8,0.3-1.5,0.8-2.3,1C53.7,71.3,53.3,71,52.9,70.9z M76.7,74.7c-0.4,0.3-1.1,0.4-1.7,0.4
      c-0.2,0-0.6-0.8-0.4-1.1c0.1-0.4,0.7-0.7,1-1.1c0.6-0.7,1.2-1.3,1.8-2c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.1-0.1,0.2-0.2
      c0.6-0.3,1.2-0.7,1.8-1c0,0.1,0.1,0.3,0.1,0.3c-0.6,0.4-1.3,0.7-1.9,1c-0.2,0.1-0.3,0.2-0.5,0.3c0,0.3-0.1,0.7-0.1,1
      c0.4-0.1,0.7-0.2,1.1-0.3c0.3-0.1,0.6-0.1,0.9-0.2c-0.1,0.3-0.2,0.6-0.4,0.8C78.2,73.2,77.5,74.1,76.7,74.7z M79.3,74.9
      c-0.6-0.3-0.8-0.4-1-0.5c0.6-0.4,1.2-0.9,1.8-1.3c0.1,0.2,0.4,0.5,0.4,0.5C80.1,74.1,79.7,74.5,79.3,74.9z M80,54.9
      c-0.1,0.2-0.4,0.4-0.6,0.5c0.2,0,0.5,0.1,0.7,0.1s0.4,0.2,0.4,0.2c-0.1,0.9-0.2,1.8-0.4,2.7c-0.1,0.5-1.6-0.1-1,1.1
      c0.1,0.1-0.3,0.4-0.5,0.7c0.2,0.4,1.4,0.2,2,0.1c-0.4,0.8,0.8,2.4-1.2,2.5c-0.3,0-0.7,0.6-1,0.9c0.4,0.1,0.8,0.1,1.1,0.3
      c0.3,0.2,0.8,0.6,0.8,0.9c0.2,1.8-0.2,3.2-2.3,3.7c-2.2,0.6-3,3.6-3.1,3.7c-2.9,2.1-6.6,4.3-10.4,3.7c0.2-0.6,1.6-2.1,2.2-2.7
      c-0.8,0.5-2.1,0.8-2.3,1.5c-0.9,2.4-2.4,1.2-3.7,0.7c-2.2-0.8-4.2-1.9-6.3-2.9c-0.2-0.1-0.2-0.6-0.3-0.9c0.6-0.1,1.3-0.2,1.9-0.2
      c0.1,0,0.7,1.5,0.9,1.4c1.3-0.2,4.4-0.8,4.8-0.9c-0.2,0.2-1.5,1.5-1.9,1.9l0,0c2-1.3,4-2.6,6-3.9c0.2-0.1,0.3-0.4,0.4-0.4
      c3.5,0.2,5.2-3.1,8.1-4.2c0.6-0.2,0.7-1.4,1.1-2.1c0-0.4-1.6,0.9-2.4,1.4c0.2-0.7,0.6-1.5,0.6-2.2c-0.3-3.5,2.5-3.6,4.6-4.5
      c0.2-0.1,0.4-0.3,0.6-0.4c-0.2,0-0.5-0.2-0.7-0.1c-1.1,0.2-3.9,0.7-4.2,0.7c0.2-0.5,0.3-1.4,0.7-1.6c1.2-0.8,2.6-1.3,3.9-2
      c0.2-0.1,1-0.9-0.3-0.7c-6.9,1.8-8.5-7.3-8.5-7.4c0.1-1,2.9-2.3,3.8-1.6c0.1,0.1-0.2,0.8-0.4,1.1c-0.3,0.4-0.7,0.8-1,1.2
      c0.4,0.1,0.9,0.5,1.1,0.3c0.7-0.4,1.2-1,1.8-1.6c0.2-0.2,0.2-0.9,0.3-0.9c2.2,0.2,3.3-1.8,4.9-2.6c0.3-0.1,0.6-0.2,1-0.3
      c-0.1,0.4,0,0.9-0.2,1c-0.9,0.6-1.9,1.1-2.8,1.6c-0.3,0.2-0.5,0.3-0.8,0.5c0.3,0.1,0.5,0.2,0.8,0.2c0.1,0,0.2,0,0.4,0
      c-0.1,0.1-0.2,0.3-0.4,0.4c-1.3,1-2.7,2-4,3c-0.5,0.4-0.9,1.1-0.9,1.6c0,0.4,0.9,0.6,1.4,1c0.3,0.2,0.6,0.4,1,0.7
      c-0.3,0.2-0.5,0.4-0.8,0.6c0.4-0.1,0.8-0.2,1.2-0.2c1.1-0.1,2.2-0.3,3.4-0.3c0.6,0,1.1,0.2,1.7,0.3C80.8,53.5,80.4,54.2,80,54.9z
       M79.1,49.2c0.6,0.4,2.2-1.1,2.1,0.9c0,0.3-0.5,0.7-0.7,0.7c-1.6-0.1-3.1-0.3-4.7-0.5c-0.1,0-0.4-0.7-0.3-0.7
      c1.9-1.3,3.9-2.6,5.8-3.9c0.3,1.7,0.1,3.1-2.2,3c-0.2,0-0.5,0.2-0.7,0.3C78.5,49.1,78.9,49.1,79.1,49.2z'
        />
      </g>
      <g id='e'>
        <path
          fill='#7269FE'
          d='M18.9,55.7c-0.1-0.1-0.2-0.2-0.3-0.3c0.6-0.4,3.6-2.6,4-1.2c0.2,0.7-0.6,0.6-1.1,0.8
      C20.6,55.2,19.7,55.4,18.9,55.7z'
        />
        <path
          fill='#7269FE'
          d='M18.2,60.9c6.1-0.6,12.2-0.9,18.3-1.9c1-0.2,2.1-0.4,2.7-1.2c0.6-0.9,0.6-2.4,0.6-3.4c0-2.7-0.2-5.3-1.1-7.8
      c-0.8-2.3-2.3-4.3-4.5-5.5c-2.3-1.3-5.2-2.4-7.7-3c-3-0.7-5.6-0.1-8.4,1c-2.4,1-5,2.2-6.9,4c-2,1.8-2.9,4-3.7,6.4
      c-0.4,1.2-0.9,2.4-1.4,3.5c-0.5,1.3-0.6,2.5-0.6,4c0,1.4-0.1,2.9,0,4.3c0.1,1.4,0.9,2.6,1.4,3.9c0.8,2.3,1.3,5,3.3,6.6
      c1.7,1.5,4.2,2.3,6.3,3.1c2.6,1,5.3,1.7,8.1,1.9c2.8,0.2,5.9,0.2,8.6-0.5c1.7-0.4,5.4-1.8,5.8-3.8c0.2-1.1-0.1-2.6-0.2-3.8
      c-0.1-0.9,0.2-2.6-0.7-3.2c-0.9-0.5-2.2,0.5-3,0.8c-1.5,0.6-3.1,0.9-4.8,1c-3,0.1-6.3-0.6-9.1-1.9c-1.1-0.5-1.8-1.2-2.5-2.1
      C18.3,62.9,16.7,61,18.2,60.9z M37.5,57.2C34.3,58,19.7,59,18.1,58.8c1.7-1.3,4-1.1,5.8-2.3c-0.6-0.1-1.2-0.1-1.9-0.2
      c0.9-0.5,1.9-0.9,2.8-1.5c0.6-0.5,0.9-1.4,1.6-1.7c0.6-0.3,1.4-0.1,2.1,0c1.2,0.2,2.3,0.2,3.5,0.1c-1.2,0.5-2.5,1-3.8,1.4
      c-1,0.3-2.2,0.5-3,1.2C26,56,26.9,56,27.8,56c-1.4,0.7-2.7,1.3-4.1,2c2.7,0,5.1-0.6,7.7-1.2c1.1-0.3,2.1-0.7,3.2-0.8
      c1.2-0.1,2.4,0.2,3.6,0.5C37.9,56.7,37.8,57.2,37.5,57.2z M28.1,45.3c-1.3,0.4-2.4,0.4-3.7-0.1c1.6-0.3,3.1-0.6,4.7-0.6
      C28.8,44.8,28.5,45.2,28.1,45.3z M32.4,46c0.1,0,0.2,0.1,0.3,0.1c-1,0.5-1.9,1-3.1,1.6c-0.2-0.3-0.4-0.6-0.6-0.9
      C30.2,46.6,31.3,46.3,32.4,46z M27.8,48.3c0.6,0.6,0.7,1.7,1,2.6c-2.9,0.5-5.8,0.3-8.7,0.4c-1.3,0-4,0-2.3-1.9
      C20,46.9,25.3,45.6,27.8,48.3z M34.6,55.9C34.6,55.9,34.6,55.8,34.6,55.9L34.6,55.9z M37.9,52.5c-0.6,0.7-1.5,0.8-2.4,0.5
      c0.3-0.2,1.3-0.3,1.4-0.7c0.1-0.5-0.6-0.6-0.6-1C36.6,50.3,38.7,51.6,37.9,52.5z M36.7,46.9c1.2,0.3,1.2,2.5,0.4,3.1
      c-0.7,0.5-1.6,0.5-2.3,0.5c-1.1-0.1-2.2-0.1-3.3,0c1.4-0.9,2.8-1.7,4.2-2.5c-1.3-0.2-2.4-0.4-3.6-0.2C33.3,47,35.2,46.4,36.7,46.9
      z M35.2,44.3c0.6,0.4-0.4,0.6-0.5,0.7c-0.7,0.2-1.6,0.1-2.4,0.2c-0.6,0.1-1.5,0.4-1.3-0.3c0.1-0.6,1.7-1.5,2.1-1.8
      c-1.8-0.3-3.3,0.4-5.1,0.3c0.1-0.7,2.4-2.3,3.1-2.3c0.6,0,1.2,0.8,1.6,1.2C33.5,43,34.4,43.6,35.2,44.3z M22.8,42
      c0.5-0.8,1.9-1,2.8-1.1c1.2-0.3,2.6-0.7,3.7,0.1c-1.1,0.5-2.3,0.6-3.4,0.8c-1,0.2-2.1,0.6-3.1,0.4C22.7,42.2,22.8,42.1,22.8,42z
       M24.6,42.8c0.2,0,0.4,0.1,0.5,0.1c-0.7,1.3-3.4,2-4.7,2.5c-1,0.4-2,0.7-3.1,0.7c-0.7,0.1-2.2,0.2-2.9-0.2s-0.3-0.6,0.3-0.9
      c0.9-0.4,1.9-0.5,2.8-0.7C19.9,43.8,22.2,43.3,24.6,42.8z M12.6,44.3c0.9-0.7,1.8-1.7,2.8-2.2c1.1-0.6,1.9,0,3.1,0.3
      c-1.8,1-4.2,2.1-6.2,2.5C12.4,44.7,12.5,44.4,12.6,44.3z M11.7,48.4c1-0.3,2-0.5,3-0.8c0.9-0.2,2-0.8,2.9-0.4
      c-0.8,0.6-1.3,1.3-2.3,1.5c-1.2,0.3-2.6,0.1-3.8,0C11.6,48.6,11.6,48.4,11.7,48.4z M15,50.3c-0.3,1.2,0,1.9-1.3,2.2
      s-2.7,0.4-4.1,0.3C11.2,51.7,13.1,50.1,15,50.3z M10,49.5c0.5-0.5,1.7,0.2,2.2,0.4c-1.3,0.9-2.2,1.2-3.8,1.5
      C8.9,50.7,9.4,50.1,10,49.5z M7.9,58.8c-0.2-0.9-0.6-2.2,0.1-2.9c0.8-0.8,3-0.9,4-0.6c-1,0.5-1.9,1.2-2.8,1.8
      c2-0.3,3.8-0.9,5.7-1.5C14.4,55.9,8.2,60.1,7.9,58.8z M22,74.3c-1.8,0.7-3.7-0.5-5.3-1.2c-1-0.4-2-0.9-3-1.2
      c-0.9-0.4-1.2-0.6-1.7-1.4c1.6-0.5,3.2-0.8,4.8-1.2c-0.5,0.6-0.9,1.2-1.4,1.8c1.3,0.1,2.2,0.3,3.5-0.1c1.2-0.4,2.4-0.9,3.6-1.1
      c-1.2,1.2-1.8,2.3-2.4,3.9c1.1-0.1,2-0.3,3.1-0.5C22.8,73.6,22.4,74.1,22,74.3z M36,67.5c0.2-0.1,0.6,0.2,0.8,0.4
      c-0.6,0.7-1.1,1.4-1.6,2.2c1.1-0.6,1.1-0.6,2.1,0.2l-3.8,3.9c0.7-1.4,1.2-2.4,1.1-4c-1.4,0.7-2.9,1.3-3.5,2.9
      c0.8-0.2,1.4-0.5,2.2-0.8c-0.7,1.8-1.7,2.8-3.7,3c-1.1,0.1-2.2-0.1-3.3,0c-1.1,0.1-2.3,0.3-3.4,0c0.6-0.5,1-1.5,1.9-1.7
      c0.8-0.2,1.5,0.5,2.3,0.3s1.4-0.9,2-1.4c0.9-0.8,1.8-1.7,2.6-2.5c-1.1,0.5-2.2,1-3.2,1.5C27.6,71.9,27,72.4,26,72
      c0.8-0.4,1.5-0.8,2.2-1.4s1.3-1.2,2.1-1.7C32.1,67.9,34.2,68,36,67.5z M27.3,68.6c0.2,0,0.5,0.1,0.7,0.2c-0.9,0.8-2,1.3-3,1.9
      c-0.8,0.4-2,0.8-2.6,1.5C23.4,69.9,24.9,69.1,27.3,68.6z M21.6,67.6c0.5,0.2,0.8,0.3,1.1,0.4c-1,0.5-2.3,1.7-3.5,1.1
      C20,68.6,20.8,68.1,21.6,67.6z M14.3,68.7c0-0.1,0.1-0.1,0.1-0.2c-2.2,1-4.8,1.2-5.4-1.6c-0.9-3.6,2.8-4,5.5-3.5
      c-1.6,1-3.5,1.5-4.7,3c2.3,0.7,4.8-0.3,7,0.4c-0.5,0.4-0.9,0.7-1.4,1.1c1.6-0.1,3-1.1,4.6-1C18.5,68.1,16,68.3,14.3,68.7
       M13.3,62.5c-0.2-0.2-0.4-0.4-0.7-0.6c0.8-0.5,1.5-1,2.3-1.5c0.2,0.4,0.4,0.9,0.6,1.3C14.9,62,14.1,62.2,13.3,62.5z M17,64.6
      c0.2,0.1,0.3,0.4,0.4,0.6c-1.6,0.4-3,0.5-4.7,0.3c0.7-0.4,1.4-1.1,2.3-1.3C15.7,64.1,16.4,64.4,17,64.6z M14.9,59.1
      c-0.7,0.4-1.6,0.3-2.4,0.4c2.1-0.8,3.6-2.6,5.3-4c-0.7-0.1-1.3-0.1-1.9-0.2c-0.6-0.1-0.6-0.1-0.2-0.7c-1.2-0.7-2-0.6-3.4-0.6
      c2.1-0.6,4.1-0.8,6.3-1c1.9-0.2,3.8-0.6,5.7-0.1c-0.2,0.5-0.2,1-0.5,1.4s-0.9,0.5-1.3,0.8c-0.5,0.3-0.7,0.9-1.1,1.2
      s-0.8,0.3-1.2,0.5c0-0.1-0.1-0.2-0.1-0.3C18.3,57.4,16.6,58.2,14.9,59.1z'
        />
      </g>
      <path fill='#7269FE' d='M138.5,40.4' />
    </g>
    <g id='birrete'>
      <path
        fill='#7269FE'
        d='M355.1,26.3l-12.5-14.7c-0.2-0.2-0.5-0.4-0.8-0.4l-13.9-2.5c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.3,0.4
    c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.2,0.2,0.4,0.3l13.9,2.5l8.9,10.5l-1.6,2.8c-0.4-0.1-0.9,0-1.3,0.2l-2.4-0.4l0.9-1.5
    c1.3-2.2-6.3-6.6-7.1-7.1c-0.9-0.5-8.5-4.8-9.7-2.6l-0.9,1.5l-5.8-6.8c0.2,0,0.5,0,0.6-0.2c0.2-0.2,0.2-0.4,0.1-0.7
    c-0.1-0.2-0.3-0.4-0.5-0.4c-0.5-0.1-1.1,0.2-1.4,0.6c-0.3,0.5-0.2,1.1,0.1,1.5l6.1,7.2l-2,3.5c-1.3,2.2,6.3,6.6,7.1,7.1
    c0.9,0.5,8.5,4.8,9.7,2.6l2-3.5l2,0.4l-0.7,1.3c-0.4,0.6-0.1,1.4,0.5,1.8l1.1,0.7c0.6,0.4,1.4,0.1,1.8-0.5l1-1.7
    c0.2-0.3,0.2-0.6,0.2-0.9l3.4,0.6c0.5,0.1,1.1-0.2,1.4-0.6C355.5,27.3,355.4,26.7,355.1,26.3z M333.5,26.5
    c-4.2-2.4-6.4-4.6-6.6-5.3c0.7-0.2,3.8,0.7,7.9,3.1c4.2,2.4,6.4,4.6,6.6,5.3C340.6,29.7,337.6,28.9,333.5,26.5z M342.1,28.3
    c-1.6-2.2-6.1-4.9-6.7-5.2c-0.7-0.4-5.2-2.9-7.9-3.2l2.8-4.9c0.6-0.2,3.7,0.6,8,3c4.3,2.5,6.5,4.8,6.6,5.4L342.1,28.3z M348.1,29.7
    L347,29l1-1.7c0.2-0.3,0.6-0.4,0.9-0.2c0.3,0.2,0.4,0.6,0.2,0.9L348.1,29.7z M350,26.4l1.4-2.4l2.7,3.2L350,26.4z'
      />
      <path
        fill='#7269FE'
        d='M324.9,9.3c-0.2-0.1-0.3-0.3-0.3-0.6c0-0.2,0.1-0.4,0.3-0.6c0.2-0.1,0.4-0.1,0.7,0l0,0
    c0.3,0.2,0.4,0.6,0.2,0.9C325.7,9.4,325.3,9.5,324.9,9.3z'
      />
    </g>
  </svg>
)

export default LogoEducalista
