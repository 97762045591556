import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { normalizeString } from '../utils/strings'
import { alphabeticallyByProp } from '../utils/sorting'
import { Link } from 'gatsby'

const MIN_LETTERS_TO_SEARCH = 1

const Search = ({ schools }) => {
  const [searchTerm, setSearchTerm] = useState('')

  const schoolsPassingSearch =
    searchTerm.length < MIN_LETTERS_TO_SEARCH
      ? []
      : schools
          .filter(school => {
            const normalizedName = normalizeString(school.name)
            const normalizedSearchTerm = normalizeString(searchTerm)
            return normalizedName.includes(normalizedSearchTerm)
          })
          .sort(alphabeticallyByProp('name'))
          .slice(0, 5)

  return (
    <div className='school-search'>
      <input
        type='text'
        value={searchTerm ?? ''}
        onChange={evt => setSearchTerm(evt.target.value)}
        placeholder='Buscar colegio por nombre'
      />
      <ul className='search-results'>
        {schoolsPassingSearch.map(school => (
          <li key={school.name}>
            <Link to={`/colegio/${normalizeString(school.name, '-')}`}>
              {school.name}
            </Link>
          </li>
        ))}
        {schoolsPassingSearch.length === 0 &&
          searchTerm.length >= MIN_LETTERS_TO_SEARCH &&
          'No hay centros con ese nombre'}
      </ul>
    </div>
  )
}

Search.propTypes = {
  schools: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
}

export default Search
