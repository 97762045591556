import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Svg from 'react-inlinesvg'
import Search from './search'
import LogoEducalista from './logoEducalista'
import { filtersToString } from '../utils/filters'
import { document } from 'browser-monads'
import Seo from './seo'

const HomeHeader = ({ filters, schools, filterSchoolsCount }) => {
  const [isFilterLanding, setIsFilterLanding] = useState(false)
  const isFirstRender = useRef(true)

  useEffect(() => {
    const pathName = document.location.pathname
    if (pathName !== '/') {
      setIsFilterLanding(true)
    }
  }, [])

  useEffect(() => {
    if (!isFirstRender.current) {
      setIsFilterLanding(false)
    }
    isFirstRender.current = false
  }, [document.location.pathname])

  return (
    <div className='home-header'>
      {Object.keys(filters).length > 0 ? (
        <Seo title={filtersToString(filters) + ' en Zaragoza'} />
      ) : (
        <Seo title='Elige colegio en Zaragoza para tu hijo/a gracias a la opinión de otros padres y madres' />
      )}
      <div className='logo'>
        <LogoEducalista />
      </div>
      <div className='home-header-content'>
        {!isFilterLanding && (
          <p className='hero-text alt-font'>
            Elige colegio para tu hijo/a gracias a la opinión de otros padres y
            madres.
          </p>
        )}
        <Search schools={schools} />
        {isFilterLanding && (
          <>
            <h1>{filtersToString(filters)} en Zaragoza</h1>
            <p className='filter-title-description'>Listado con los {filterSchoolsCount} {filtersToString(filters)} en Zaragoza. Escoge colegio para tus hijos/as gracias a las opiniones de otros padres y madres.</p>
          </>
        )}
        <div className='social'>
          <a
            href='https://twitter.com/educalista_es'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Svg width={24} src='/images/logo-twitter.svg' />
          </a>
        </div>
      </div>
    </div>
  )
}

HomeHeader.propTypes = {
  filters: PropTypes.object.isRequired,
  schools: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  filterSchoolsCount: PropTypes.number.isRequired,
}

HomeHeader.defaultProps = {
  isFilterLanding: false,
}

export default HomeHeader
